#documents-table {
  margin-bottom: 40px;
  margin-top: 30px;
}
#documents-table h3 {
  font-weight: bold;
  color: #ea650d;
}

#documents-table .headers-container {
  display: flex;
  justify-content: space-around;
  margin: 30px 125px 10px 5px;
}
/* #documents-table .headers-container [data-testid="Label"]:first-child {
  margin-right: 10px;
} */

#documents-table [data-testid="Collapsible"] [data-testid="Label"] {
  width: 900px;
  padding: 0 25px;
}

[data-testid="Collapsible"] .MuiAccordionSummary-expandIconWrapper {
  display: none;
}

#documents-table .collapsible-label-container {
  display: flex;
  justify-content: space-between;
  margin-right: 100px;
}
#documents-table .collapsible-label-container .item {
  flex-basis: 100%;
  margin-left: 87px;
}
#documents-table .collapsible-label-container .item:last-child {
  padding-left: 37px;
  font-weight: normal;
}
[data-testid="Table"] .MuiDataGrid-columnHeaders {
  border-bottom-width: 0 !important;
}

#documents-table b.hidden {
  display: none;
}

#documents-table .table {
  border-bottom: 2px solid #dee2e6;
}

#documents-table .expand {
  background-color: #f8f6f5;
}

#documents-table .documents-collapse {
  background-color: #f8f6f5;
}

#documents-table .documents-collapse img {
  margin-left: 5px;
}

#documents-table .documents-collapse img.download {
  margin-left: 10px;
}

#documents-table .documents-collapse a.active {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

#documents-table .documents-collapse .overdue {
  color: #ea650d;
  font-size: 16px;
  letter-spacing: 0;
}

#documents-table .documents-collapse .pending {
  color: #e64415;
  font-size: 16px;
  letter-spacing: 0;
}

#documents-table .collapsing {
  transition: none;
}

#documents-table .table-customer-document {
  padding-top: 20px;
}

#documents-table [data-testid="Table"] {
  padding: 0 20px;
}

#documents-table [data-testid="Table"] .MuiDataGrid-footerContainer {
  display: none;
}

.list-container-icon {
  display: flex;
  gap: 10px;
  cursor: pointer;
}
.list-container-icon div:first-child span {
  margin-top: 15px;
}

.list-container {
  margin: 0 20px;
  border-bottom: 1px solid #e5ded9;
  border-top: 1px solid #e5ded9;
  padding-bottom: 15px;
}
.list-container > div:last-child {
  padding-bottom: 20px;
}
.list-container [data-testid="Label"] {
  margin-top: 15px;
  padding: 0 !important;
  color: black !important;
}

.documents-select-container {
  width: 250px;
}

@media (max-width: 1200px) {
  #documents-table [data-testid="Collapsible"] [data-testid="Label"] {
    width: 880px;
  }

  #documents-table .headers-container {
    margin-left: 3px;
  }
}

@media (max-width: 991.98px) {
  #documents-table [data-testid="Collapsible"] [data-testid="Label"] {
    width: 550px;
    padding: 0;
  }
  #documents-table .collapsible-label-container {
    display: flex;
    justify-content: space-around;
  }
  #documents-table .collapsible-label-container .item {
    flex-basis: 100%;
    margin-left: 52px;
  }

  #documents-table .collapsible-label-container .item:last-child {
    padding-left: 60px;
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  #documents-table .headers-container {
    margin: 10px 0px 0px 15px;
  }
  #documents-table [data-testid="Collapsible"] [data-testid="Label"] {
    cursor: pointer !important;
    display: flex;
    max-width: 300px !important;
    padding: 0 15px;
  }
  #documents-table .collapsible-label-container {
    width: 500px;
  }
  #documents-table .collapsible-label-container .item {
    flex-basis: 100%;
    margin-left: 25px;
  }

  #documents-table .collapsible-label-container .item:last-child {
    padding-right: 30px;
    padding-left: 50px;
    text-align: start;
  }

  .MuiDataGrid-columnHeaderTitle {
    text-align: start !important;
  }
}

@media (max-width: 499.98px) {
  #documents-table b.hidden {
    display: block;
  }

  #documents-table [data-testid="Collapsible"] [data-testid="Label"] {
    min-width: 200px;
    padding: 0 15px;
  }

  #documents-table .collapsible-label-container {
    width: 250px;
    display: flex;
    gap: 20px;
  }

  #documents-table .collapsible-label-container .item:last-child {
    padding: 0;
    margin: 0;
  }
  #documents-table .chevron-down {
    display: flex;
    align-items: center;
  }

  .headers-container {
    display: none !important;
  }
}
