#account-tabs .account-table-container {
  position: relative;
  margin-bottom: 50px;
}

#account-tabs .account-table-container label {
  color: #ea650d;
  font-size: 16px;
  font-weight: bold;
}

#account-tabs .account-table-container .premium {
  margin-top: 60px;
}

#account-tabs [data-testid="Table"] span {
  white-space: normal;
}
