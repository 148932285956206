.footer {
  background-color: #f1edeb;
  font-family: Calibri, Arial, sans-serif;
  margin-top: 30px;
}

.footer .top {
  display: flex;
  justify-content: space-between;
}

.footer .top > div {
  padding-top: 37px;
  padding-bottom: 22px;
  min-height: 100px;
}

.footer .top .left {
  width: 35%;
}

.footer .top .title {
  text-align: right;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 15px;
}

.footer .top .title span {
  font-size: 16px;
  color: #4a4a4a;
  font-weight: bold;
  letter-spacing: 0;
}

.footer .top .links {
  display: inline-block;
}

.footer .top .links a,
.footer .top .links span {
  display: block;
  margin-bottom: 15px;
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: 0;
  text-decoration: none;
}

.footer .top .links a.button {
  width: 139px;
  border-radius: 3px;
  background-color: #999999;
  color: #fff;
  height: 28px;
  line-height: 28px;
  text-align: center;
}

.footer .top .center {
  width: 35%;
}

.footer .top .links .social a {
  display: inline-block;
  text-decoration: none;
  margin-right: 5px;
}

.footer .top .right {
  width: 20%;
}

.footer .top .right a {
  display: inline-block;
}

.footer .middle {
  border-top: 1px solid #cac7c7;
  padding: 14px 0;
}

.footer .middle a {
  display: inline-block;
  line-height: 1;
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: 0;
}

.footer .middle .divide {
  width: 1px;
  border-right: 1px solid #cac7c7;
  margin: 0 25px;
}

.footer .bottom {
  border-top: 1px solid #cac7c7;
  padding: 23px 0;
}

.footer .bottom span {
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 11px;
}

@media (max-width: 767.98px) {
	.footer .bottom {
    padding: 23px 0 100px 0;
  }
}

@media (max-width: 499.98px) {
  .footer .top {
    display: block;
    position: relative;
  }

  .footer .top .left {
    width: 46%;
    min-width: 170px;
  }

  .footer .top .center {
    width: 46%;
    padding-top: 0px;
    min-width: 170px;
  }

  .footer .top .right {
    position: absolute;
    top: 0;
    right: 0px;
    width: auto;
  }

  .footer .middle .divide {
    margin: 0 5px 0 0px;
  }
}

@media (max-width: 399.98px) {
  .footer .middle .divide {
    display: none;
  }

  .footer .middle a {
    display: initial;
  }

  .footer .middle a:after {
    content: " ";
    display: block;
    width: 100%;
    height: 10px;
  }
}

@media (max-width: 319.98px) {
	.footer .top .left {
    padding: 0;
    min-height: auto;
  }

  .footer .top .center {
    min-height: auto;
  }

  .footer .top .right {
    position: relative;
    padding-top: 0;
  }

  .footer .top .title {
    opacity: 0;
    margin: 0;
  }

  .footer .top .links a.button {
    margin-bottom: 0;
  }
}