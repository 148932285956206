.home-contracts.table-container {
  margin-top: 50px;
}

@media (max-width: 767.98px) {
  .home-contracts.table-container {
    margin-top: 30px;
  }
}

.home-contracts .table-label {
  color: #ee7f00;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}

.home-contracts .table-label-with-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-contracts .go-to-details {
  display: flex;
  align-items: center;
  color: currentColor;
  text-decoration: none;
}

.home-contracts .go-to-details .go-to-details-text {
  margin-right: 10px;
}

.home-contracts .table {
  margin-bottom: 4rem;
}

.home-contracts .overdue {
  color: #ea650d !important;
}

.home-contracts .overdue span {
  color: #414141 !important;
}

.home-contracts b.hidden {
  display: none;
}

@media (max-width: 767.98px) {
  .home-contracts b.hidden {
    display: block;
  }

  .home-contracts .table-label {
    font-size: 24px;
    line-height: 30px;
  }
}

@media (max-width: 537px) {
  .home-contracts .go-to-details .go-to-details-text {
    display: none;
  }
}
