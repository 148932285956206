.role-selection-container {
  margin-bottom: 100px;
}
.roles-options-selection {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.roles-options-selection-hr {
  justify-content: center;
}

.card-role-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0 0px 40px;
}

[data-testid="role-option-header"] {
  font-weight: bold;
  color: #ea650d !important;
}

.CircularArrows {
  padding-right: 6px;
}

.selected-contract-hr-tabs [role="tablist"] {
  gap: 50px !important;
}

.contract-hr-tab-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.search-contracts-hr {
  width: 400px;
  position: absolute;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contract-hr-tab-container
  .contract-table-header
  [data-testid="Label"]:nth-child(5),
.contract-hr-tab-container .contract-table-header div:nth-child(5) {
  grid-column-start: 6;
  grid-column-end: 8;
}

.small-no-type {
  font-size: 15px;
}

.name-icon {
  display: flex;
  gap: 5px;
  white-space: nowrap;
}

.selected-contract-coverages .expand {
  background-color: #f8f6f5;
}
.coverage-hr-table {
  background-color: #f8f6f5;
  padding-left: 30%;
}
.group-pension-files {
  margin-top: -30px;
  padding-bottom: 20px;
}

.group-pension-file-download {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.group-pension-file-download label {
  margin-right: 5px;
}
.payments-hr-table {
  background-color: #f8f6f5;
  padding: 0 2vw;
}

.MuiDataGrid-row--detailPanelExpanded {
  background-color: #f8f6f5;
}

.MuiDataGrid-row--detailPanelExpanded:hover {
  background-color: #f8f6f5 !important;
}

[data-testid="payments-hr-table"] .MuiDataGrid-row--detailPanelExpanded {
  background-color: #f8f6f5 !important;
}

[data-testid="payments-hr-table"] .MuiDataGrid-columnHeaders {
  border-bottom-width: 0 !important;
}

.name-cell-container {
  display: flex;
  flex-direction: column;
}

.insurence-type-hr-cell {
  white-space: normal !important;
  font-size: 14px;
}

.excel-export-container {
  display: flex;
  justify-content: flex-end;
}

.excel-export {
  cursor: pointer;
  display: flex;
  gap: 10px;
}

.detail-panel-payments-selected-container {
  display: flex;
  gap: 10vw;
  padding-left: 8vw;
  padding-top: 5vh;
  padding-bottom: 2vh;
  background-color: #f8f6f5;
}
.detail-panel-payments-selected-container img {
  cursor: pointer;
}

.payments-hr-filter-container {
  display: flex;
  gap: 1vw;
  margin-top: 24px;
}

.section-container {
  display: flex;
  cursor: pointer;
  margin-top: 10px;
  width: 320px;
}

.claims-form-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.claims-searchInput-container [data-testid="SearchInput"] .MuiButtonBase-root {
  height: 42px;
  width: 42px;
}

.selected-contract-overview .hr-info-label-container {
  display: flex;
  gap: 20px;
}
.selected-contract-overview .contracting-hr {
  width: 280px;
}
.selected-contract-overview .contracting-hr p {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}
.selected-contract-overview .contracting-hr span {
  color: #414141;
  font-size: 16px;
}
.select-nn-hr {
  width: 253px;
}
@media (max-width: 992.98px) {
  .payments-hr-filter-container {
    flex-direction: column;
    margin-top: 0;
  }

  .payments-select-hr {
    flex-direction: row;
  }
}

.contract-hr-pension-insuredmembers
  .MuiDataGrid-row:last-child
  .groupPensionDetailRowCell {
  border-bottom: none !important;
}

@media (max-width: 767.98px) {
  .contracts {
    display: flex;
    flex-direction: column;
  }
  .contracts .search-contracts-hr {
    position: initial;
    width: auto;
  }
  .contracts .filter-contracts {
    align-items: center;
  }
  .coverage-hr-table {
    padding-left: 0;
  }
  .claims-form-container {
    flex-direction: column;
  }

  .select-nn-hr {
    width: 100%;
  }
  .payments-select-hr {
    flex-direction: column;
  }

  .roles-options-selection {
    flex-direction: column;
  }
}
