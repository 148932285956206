@media (max-width: 767.98px) {
  .container-logo-title-icons {
    height: 83px;
  }
}

.logo-login .myNN {
  left: auto;
  color: #414141;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
}

@media (max-width: 767.98px) {
  .logo-login .myNN {
    font-size: 20px !important;
  }
}

@media (max-width: 575.98px) {
  .logo-login .myNN {
    font-size: 18px !important;
  }
}

@media (max-width: 399.98px) {
  .logo-login .myNN {
    font-size: 16px !important;
  }

  .guest .logo-login .myNN {
    display: none;
  }
}

@media (max-width: 319.98px) {
  .logo-login .myNN {
    font-size: 14px !important;
  }
}

.rigth-login-component {
  display: flex;
}
.nn-icons-header {
  display: flex;
  margin: 0 auto;
}
.container-logo-title-icons {
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.container-logo-title-icons .item {
  margin: auto 0;
}

.login > a {
  display: inline-block;
  position: relative;
  top: -11px;
}

.login .login-info {
  margin: 0 auto;
}

.login-info p,
.login-info p a {
  display: block;
  margin: 0;
}

.login .username {
  color: #ea650d;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.login .logout-remaining-time {
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
}

.login .profile-icon {
  padding-right: 6px;
  padding-left: 10px;
}

.login .inbox-icon {
  padding-right: 10px;
  position: relative;
}

.login .inbox-icon .message-count {
  position: absolute;
  bottom: 12px;
  right: 4px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  background-color: #e64415;
  border-radius: 20px;
  margin-bottom: 8px;
}

.navigation {
  text-align: justify;
}
.navigation-HR {
  text-align: justify;
  width: 500px;
}

.navigation a {
  display: inline-block;
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.navigation a:focus {
  color: #ea650d;
  outline: none;
}

#inner-root .navigation .activeLink {
  color: #ea650d;
  outline: none;
}

.navigation a:hover {
  color: inherit;
  text-decoration: none;
}

/* This should be uncommented when the "online payment" button is implemented, and the above .support rule should be removed */
.navigation .online-payment {
  margin-right: 0;
  color: #fff !important;
  background: #ea650d;
  padding: 0 5px;
  border-radius: 3px;
  height: 28px;
  line-height: 28px;
}

.navigation a.online-payment:focus,
.navigation a.online-payment:hover {
  color: #fff;
}

.force-same-line {
  width: 100%;
  display: inline-block;
}

/* end header (logged in user) */

/* start header (guest) */

.guest .logo-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.guest .logo-login > div {
  vertical-align: top;
  width: 33%;
  bottom: auto;
}

.guest .logo-login > div:last-child {
  width: 34%;
  text-align: right;
}

@media (max-width: 399.98px) {
  .guest .logo-login > div:last-child {
    font-size: 12px;
  }
}

.guest .logo-login a,
.guest .logo-login span {
  display: inline-block;
  text-decoration: none;
}

.guest .logo-login .myNN {
  color: #414141;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  left: auto;
}

.guest .login {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  text-align: right;
  padding-bottom: 10px;
}

.guest .login a {
  margin-left: 10px;
  font-weight: bold;
  color: #ea650d;
  text-decoration: none;
  top: auto;
}

/* end header (guest) */
#outer-root .logo-login .login {
  display: none;
}
@media (min-width: 768px) {
  #outer-root .logo-login .login {
    display: inline-block;
  }
}
#outer-root .navigation {
  text-align: justify;
  display: none;
}
@media (min-width: 768px) {
  #outer-root .navigation {
    display: block;
  }
}

@media (max-width: 575.98px) {
  /* .logo-login {
    height: 64px;
  } */
  .container-logo-title-icons {
    height: 75px;
  }
}
