#registration-body .online-payment-form .payamount {
  font-size: 36px;
  line-height: 30px;
  font-weight: bold;
}

#registration-body .online-payment-form .payFrequency {
  font-size: 14px;
}

#registration-body .online-payment-form .payBold {
  font-weight: bold;
}
#registration-body .online-payment-form .pending-text {
  display: block;
  font-weight: bold;
  color: red;
  margin-top: 10px;
}

#registration-body .right .top .online-payment-form .form-element {
  margin-bottom: 4px;
}

#registration-body .online-payment-form .chevron-link {
  margin: 7px 0;
}

.online-payment-form #active-contracts,
.online-payment-form #all-contracts {
  display: none;
}

.online-payment-form #active-contracts + label:before,
.online-payment-form #all-contracts + label:before {
  content: " ";
  display: inline-flex;
  flex-wrap: nowrap;
  position: relative;
  top: 7px;
  margin: 0 7px 0 0;
  height: 25px;
  width: 25px;
  border: 1px solid #e5ded9;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.online-payment-form #active-contracts:checked + label:after,
.online-payment-form #all-contracts:checked + label:after {
  content: " ";
  display: block;
  position: absolute;
  top: 13px;
  left: 6px;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  background-color: #ea650d;
}

.online-payment-form .active-contracts-label,
.online-payment-form .all-contracts-label {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  top: -7px;
}

.online-payment-form .active-contracts-label {
  margin-right: 35px;
}

.disclaimer-body {
  margin-top: 20px;
  display: flex;
}

.disclaimer-body .disclaimer1 {
  inline-size: 150px;
  width: 200px;
  font-size: 13px;
}

#registration-body .disclaimer {
  display: inline-block;
  width: 200px;
  font-size: 13px;
  margin-top: 20px;
}

.online-payment-container {
  width: calc(100% - 28px);
}

.filter-contracts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5px;
  margin-bottom: 10px;
}

.radio-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
