.doNotDisplay {
  display: none !important;
}

.htmlParagraph {
  font-family: Calibri, Arial, Helvetica, sans-serif;
  line-height: 1.5;
}

.htmlParagraph a {
  text-decoration: none !important;
  color: #ea650d;
}
