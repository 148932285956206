#customerSearch {
  border-top: 1px solid #f1edeb;
  margin-top: 32px;
}

#customerSearch .hidden {
  display: none;
}

#customerSearch .padding-5 {
  height: 5px;
  width: 100%;
}

#customerSearch .padding-10 {
  height: 10px;
  width: 100%;
}

#customerSearch .validation {
  font-size: 14px;
  line-height: 20px;
}

#customerSearch .signin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

#customerSearch .registration {
  margin-bottom: 50px;
}

#customerSearch .left,
#customerSearch .right {
  display: block;
  width: 100%;
}

#customerSearch .registration .left,
#customerSearch .registration .right {
  margin-bottom: 20px;
}

#customerSearch .signin .left {
  margin: 20px 0;
}

@media (min-width: 768px) {
  #customerSearch .registration {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
  }

  #customerSearch .registration .left {
    display: inline-block;
    vertical-align: top;
    width: calc(90% - 400px);
    min-width: 45%;
    margin-bottom: 0;
  }

  #customerSearch .right {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    max-width: 380px;
    margin-top: 20px;
  }

  #customerSearch .registration .right {
    margin-bottom: 0;
  }
}

#customerSearch .left img {
  display: block;
  margin: auto;
  width: auto;
  max-width: 100%;
}

#customerSearch .left h1 {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 44px;
}

@media (max-width: 499.98px) {
  #customerSearch .left h1 {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (max-width: 399.98px) {
  #customerSearch .left h1 {
    font-size: 24px;
    line-height: 29px;
  }
}

#customerSearch p {
  color: #414141;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
  padding-top: 5px;
}

@media (max-width: 499.98px) {
  #customerSearch p {
    font-size: 17px;
    line-height: 20px;
  }
}

@media (max-width: 399.98px) {
  #customerSearch p {
    font-size: 14px;
    line-height: 19px;
  }
}

#customerSearch p.text {
  padding: 20px 0;
}

#customerSearch .tc-list li {
  margin-bottom: 20px;
}

#customerSearch .right .top {
  border: 1px solid #cac7c7;
  border-radius: 4px;
  min-height: 433px;
}

@media (max-width: 399.98px) {
  #customerSearch .right .top {
    border: 0;
    min-height: 400px;
  }
}

#customerSearch .right .top > div {
  padding: 30px;
}

@media (max-width: 399.98px) {
  #customerSearch .right .top > div {
    padding: 30px 0 0 0;
  }
}

#customerSearch .right .top > div h2 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 24px;
}

#customerSearch .right .top > div h2.success {
  font-size: 24px;
  color: #414141;
  position: relative;
  padding-left: 55px;
}

#customerSearch .right .top > div h2.plain {
  font-size: 24px;
  color: #414141;
}

#customerSearch .right .top > div h2.success img {
  position: absolute;
  top: 1px;
  left: 0;
}

#customerSearch .right .top > div .form-element {
  position: relative;
  margin-bottom: 13px;
}

#customerSearch .right .top > div .form-element.right-padded {
  display: inline-block;
  width: calc(100% - 28px);
}

@media (max-width: 399.98px) {
  #customerSearch .right .top > div .form-element.right-padded {
    width: 100%;
  }
}

#customerSearch .right .top > div .form-element.prefixed span {
  display: none;
  position: absolute;
  bottom: 8px;
  left: 10px;
}

#customerSearch .right .top > div .form-element.prefixed.focused span {
  display: block;
}

#customerSearch .right .top > div .form-element.prefixed.focused input {
  padding-left: 46px;
}

#customerSearch .right .top > div .form-element > img {
  position: absolute;
  bottom: 12px;
  right: -37px;
  cursor: pointer;
}

#customerSearch .right .top > div .form-element.date img {
  top: 35px;
  right: 15px;
  pointer-events: none;
}

#customerSearch .right .top > div .form-element.checkbox {
  cursor: pointer;
}

#customerSearch .right .top > div .form-element label {
  display: block;
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0;
}

#customerSearch .right .top > div .form-element label.small {
  font-size: 14px;
  margin-top: 20px;
}

#customerSearch .right .top > div .form-element.checkbox label {
  display: inline-block;
}

#customerSearch .right .top > div .form-element.checkbox > div {
  margin-top: 10px;
}

#customerSearch .right .top > div .form-element.checkbox span {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding-left: 5px;
}

#customerSearch .right .top > div .form-element.checkbox span a {
  color: #ea650d;
}

#customerSearch .right .top > div .form-element input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #414141;
  letter-spacing: 0;
  border: 1px solid #cac7c7;
  border-radius: 3px;
  padding: 10px;
}

@media (max-width: 399.98px) {
  #customerSearch .right .top > div .form-element input {
    font-size: 14px;
  }
}

#customerSearch .right .top > div .form-element.checkbox input {
  display: none;
}

#customerSearch .right .top > div .form-element input::placeholder {
  color: #999;
  opacity: 1;
}

#customerSearch .right .top > div .form-element input:-ms-input-placeholder {
  color: #999;
}

#customerSearch .right .top > div .form-element input::-ms-input-placeholder {
  color: #999;
}

#customerSearch .right .top > div .form-element input[type="date"] {
  color: #999;
  opacity: 1;
}

#customerSearch .right .top > div .form-element.button a {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  border-radius: 3px;
  background: #ea650d;
  color: #fff;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
  padding: 0 20px;
  margin-top: 20px;
  vertical-align: top;
  margin-right: 20px;
}

#customerSearch .right .top > div .form-element.button a.alt {
  background: #fff;
  border: 1px solid #ea650d;
  color: #414141;
  line-height: 36px;
}
#customerSearch .right .top > div .form-element.button a.active {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

@media (max-width: 399.98px) {
  #customerSearch .right .top > div .form-element.button a {
    width: 100%;
  }
}

#customerSearch .right .top .small + .chevron-link {
  margin: 10px 0 20px 0;
}

#customerSearch .right .top .info {
  color: #999999;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  display: inline-block;
}

#customerSearch .right .top .info span {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

#customerSearch .right .mobile-password span {
  display: block;
}

#customerSearch .right .mobile-password span:first-child {
  color: #999999;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 22px;
}

#customerSearch .right .mobile-password span:last-child {
  color: #414141;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0;
}

#customerSearch .right .bottom {
  color: #414141;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
  margin-top: 5px;
}

@media (max-width: 399.98px) {
  #customerSearch .right .bottom {
    text-align: left;
  }
}

#customerSearch .right .bottom > div {
  display: inline-block;
  height: 34px;
  width: 248px;
}

@media (max-width: 399.98px) {
  #customerSearch .right .bottom > div {
    width: auto;
  }
}

#customerSearch .right .bottom .example {
  color: #ee6d00;
  cursor: pointer;
}

#customerSearch .right .bottom .example:hover {
  text-decoration: underline;
}

#customerSearch .reg-loader.hide {
  display: none;
}

#customerSearch .nn-container .right h1 {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 44px;
}

#customerSearch .nn-container p {
  font-size: 18px;
  margin: 0 0 10px 0;
}

#customerSearch .nn-container .right .top {
  border: none;
  min-height: none;
  border-radius: 0;
}

#customerSearch .nn-container .right .top > div {
  padding: 0;
}

#customerSearch .nn-container .right .top > div .form-element {
  display: inline-block;
  max-width: 270px;
  width: 100%;
  vertical-align: bottom;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  #customerSearch .registration.nn-container .left {
    width: 25%;
    min-width: 0;
  }

  #customerSearch .nn-container .right {
    margin-top: 0;
    max-width: none;
    width: 75%;
  }
}
