.line {
  box-sizing: border-box;
  border-top: 2px solid #f1edeb;
  position: relative;
  margin: 0;
}

.guest .line {
  margin-top: 30px;
}

.hero-banner {
  position: relative;
  height: 360px;
}

.hero-banner img {
  height: 100%;
  display: block;
}

@media (max-width: 1279.98px) {
  .hero-banner {
    left: calc((100vw - 1280px) / 2);
  }
}

@media (max-width: 767.98px) {
  .hero-banner {
    max-height: 250px;
    left: calc((100vw - 889px) / 2);
  }
}

@media (max-width: 575.98px) {
  .hero-banner {
    max-height: 198px;
    left: calc((100vw - 704px) / 2);
  }
}

.my-label {
  color: #ea650d;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 41px;
  margin-bottom: 15px;
  margin-top: 35px;
}

.my-description {
  color: #999999;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  white-space: nowrap;
}

@media (max-width: 499.98px) {
  .my-label {
    font-size: 30px;
  }
}

@media (max-width: 319.98px) {
  .my-label {
    font-size: 24px;
  }

  .my-description {
    font-size: 14px;
    line-height: 18px;
  }
}
