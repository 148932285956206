.hover-popover-arrow {
  position: absolute;
  left: 0;
  top: 0;
}

.hover-popover-arrow::before {
  position: absolute;
  left: -5px;
  margin-top: 3.5px !important;
  border-width: 1px 0 0 1px;
  border-color: #949494 transparent transparent #949494;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  border-style: solid;
  background-color: #ffffff;
}

.hover-popover-body {
  margin-top: 9px;
  margin-left: -5px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  min-width: 170px;
  max-width: 400px;
  min-height: 40px;
  overflow: auto;
  box-shadow: 0px 2px 5px 0px rgb(65 65 65 / 23%);
  border-width: 1px;
  border-style: solid;
  border-color: #949494;
  background-color: #ffffff;
  display: flex;
}