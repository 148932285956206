.eServices {
  margin-top: 20px;
}

.eServices-action {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  /* Copy Button styles until CL supports a ButtonLink variant https://teams.microsoft.com/l/message/19:4VSIh-wOTAryVczaa92mzvxZSugHE1533dkA15_ZPRE1@thread.tacv2/1729057764957?tenantId=fed95e69-8d73-43fe-affb-a7d85ede36fb&groupId=222bf6b2-8587-4e47-828d-4007f315279a&parentMessageId=1729057764957&teamName=GRP-Design%20System%20Community&channelName=General&createdTime=1729057764957 */
  & a {
    all: unset;
    margin-left: 20px;

    /* Button styles */
    font-synthesis: style;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    min-width: 64px;
    border-radius: 4px;
    /* font-family: NittiGroteskNN-TextMedium, Arial, Helvetica, sans-serif; */
    font-weight: 500;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    background-color: rgb(64, 64, 64);
    min-height: 48px;
    line-height: 1.5;
    padding: 0px 16px;

    &:hover {
      color: rgb(255, 255, 255);
      text-decoration: none;
      background-color: rgb(43, 43, 43);
      box-shadow: rgba(65, 65, 65, 0.23) 0px 2px 5px 0px;
    }
  }
}
