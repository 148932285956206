.filter-label {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  margin-top: 10px;
  display: inline-table;
  margin-right: 80px;
  position: relative;
}

.filter-label.online {
  margin-right: 2px;
}
.filter-label.online select {
  width: 100%;
}

.nn-filter {
  border: 1px solid #c3c3c3;
  padding: 8px 10px;
  border-radius: 3px;
  height: 40px;
  width: 253px;
  display: block;
  color: #999999;
  background: #fff;
  font-size: 16px;
  outline: none;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.nn-filter:focus {
  outline: none;
}

.filter-label .vertical-line {
  position: absolute;
  right: 2px;
  bottom: 1px;
  background: url(../../assets/chevron_down_2.png) no-repeat center;
  display: block;
  height: 38px;
  width: 56px;
  pointer-events: none;
  border-radius: 3px;
}

@media (max-width: 767.98px) {
  .filter-label {
    display: inline-block;
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 319.98px) {
  .filter-label {
    display: block;
    margin: 0 0 20px 0;
  }

  .nn-filter {
    width: 100%;
    font-size: 14px;
  }
}
