.selected-contract-tabs .more-coverages {
  text-align: right;
}

.selected-contract-tabs .more-coverages a {
  display: inline-flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  line-height: 24px;
  cursor: pointer;
}

.selected-contract-tabs .more-coverages img {
  display: block;
  padding: 4px 7px;
  position: absolute;
  top: 0;
  left: 0;
}

.selected-contract-tabs .more-coverages span:last-child {
  display: block;
  margin-left: 3px;
  font-size: 14px;
  letter-spacing: 0;
  color: #414141;
}

.selected-contract-tabs .selected-contract-coverages {
  margin-bottom: 40px;
  margin-top: 25px;
}

.selected-contract-tabs .selected-contract-coverages .coverage-table,
.selected-contract-tabs .selected-contract-coverages .coverage-inside-table {
  table-layout: fixed;
}

.selected-contract-tabs .selected-contract-coverages .expand {
  background-color: #f8f6f5;
}

.selected-contract-tabs .selected-contract-coverages .collapsing {
  transition: none !important;
}

.selected-contract-tabs .selected-contract-coverages b.hidden {
  display: none;
}

.contract-table-header {
  display: grid;
  padding-left: 55px;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  grid-gap: 20px;
  align-items: center;
  /* border-bottom: 2px solid #ea650d; */
}

.contract-table-header [data-testid="Label"]:first-child,
.contract-table-header div:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
}

.contract-table-header [data-testid="Label"]:nth-child(2) {
  grid-column-start: 3;
  grid-column-end: 5;
}

.contract-table-header div:nth-child(2) {
  grid-column-start: 3;
  grid-column-end: 5;
  white-space: nowrap;
}

.collapsible-table-contract-container .Mui-expanded {
  border-color: #e5ded9 !important;
}
.collapsible-table-contract-container:first-of-type .Mui-expanded {
  border-color: #ea650d !important;
}
.collapsible-table-contract-container .collapsible-container {
  width: 910px;
}
.collapsible-table-contract-container
  [data-testid="Collapsible"]
  .MuiAccordionSummary-root:hover {
  background-color: transparent !important;
}

.collapsible-table-contract-container:nth-child(2) [data-testid="Collapsible"] {
  border-top: 2px solid #ea650d;
}

.collapsible-table-contract-container .collapsible-container div span {
  font-weight: normal;
}

.collapsible-table-contract-container
  [data-testid="Collapsible"]
  [data-testid="Label"] {
  padding: 0 0 0 15px;
}
.selected-contract-coverages .collapsible-container .chevron-down {
  display: flex;
  align-items: center;
}

.collapsible-table-contract-container .collapsible-container-elements {
  width: 100%;
  padding-left: 15px;
}

.collapsible-table-contract-container
  .collapsible-container-elements
  .item:nth-child(2) {
  margin-left: 13px;
  white-space: wrap;
}
.collapsible-table-contract-container
  .collapsible-container-elements
  .item:nth-child(3) {
  white-space: nowrap;
  margin-left: 25px;
}
.collapsible-table-contract-container
  .collapsible-container-elements
  .item:nth-child(4) {
  margin-left: 30px;
}
.collapsible-table-contract-container
  .collapsible-container-elements
  .item:nth-child(5) {
  white-space: nowrap;
  margin-left: 40px;
}

.collapsible-table-contract-container
  .collapsible-container-elements
  .extra-item:nth-child(4) {
  margin-left: 30px;
  white-space: nowrap;
}
.collapsible-table-contract-container
  .collapsible-container-elements
  .extra-item:nth-child(5) {
  white-space: nowrap;
  margin-left: 35px;
}
.collapsible-table-contract-container
  .collapsible-container-elements
  .extra-item:nth-child(6) {
  white-space: nowrap;
  margin-left: 40px;
}

.collapsible-children-contract {
  background-color: #f8f6f5;
  padding: 1rem 3rem 1rem calc(70px + 1.2rem);
}

.collapsible-children-contract [data-testid="Label"] {
  padding: 0 0 10px 0 !important;
  color: #ee7f00;
  border-bottom: 2px solid #ea650d;
  margin-bottom: 5px;
}
.collapsible-children-contract .contract-description {
  display: flex;
  border-bottom: 1px solid #dee2e6;
}
.collapsible-children-contract .contract-description div {
  flex-basis: 0%;
  flex-grow: 5;
}
.collapsible-children-contract .contract-description div:last-child {
  flex-grow: 1;
}

.selected-contract-tabs
  .selected-contract-coverages
  .collapse-inside-table
  .special-terms-desc,
.selected-contract-tabs
  .selected-contract-coverages
  .collapse-inside-table
  .special-terms-name {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: normal;
  width: 100%;
}

.selected-contract-tabs
  .selected-contract-coverages
  .collapse-inside-table
  .special-terms-desc
  > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 115px);
  display: inline-block;
  vertical-align: top;
}

.selected-contract-tabs
  .selected-contract-coverages
  .collapse-inside-table
  .special-terms-desc.active
  > div {
  width: 100%;
  white-space: normal;
  overflow: auto;
}

.collapsible-children-contract .special-terms-desc {
  margin: 30px 0 16px 0;
}
.collapsible-children-contract .period {
  font-weight: bold;
}

@media (max-width: 991.98px) {
  .collapsible-table-contract-container
    .collapsible-container-elements
    .item:nth-child(2) {
    margin-left: 5px;
  }
  .collapsible-table-contract-container
    .collapsible-container-elements
    .item:nth-child(3) {
    margin-left: 10px;
  }
  .collapsible-table-contract-container
    .collapsible-container-elements
    .item:nth-child(4),
  .collapsible-table-contract-container
    .collapsible-container-elements
    .extra-item:nth-child(4) {
    margin-left: 10px;
  }
  .collapsible-table-contract-container
    .collapsible-container-elements
    .item:nth-child(5),
  .collapsible-table-contract-container
    .collapsible-container-elements
    .extra-item:nth-child(5) {
    margin-left: 15px;
  }

  .collapsible-table-contract-container
    .collapsible-container-elements
    .extra-item:nth-child(6) {
    margin-left: 15px;
  }

  .collapsible-table-contract-container .collapsible-container {
    width: 100%;
  }
  .collapsible-table-contract-container .collapsible-container-elements {
    max-width: 650px;
  }

  .contract-table-header {
    grid-gap: 10px;
    align-items: center;
  }

  /* .selected-contract-tabs .selected-contract-coverages > .coverage-table.extra-column {
    table-layout: initial;
  } */

  /* .collapsible-container .chevron-down {
    align-items: start;
  } */
}

@media (max-width: 767.98px) {
  .selected-contract-coverages .collapsible-container-elements {
    display: flex;
    gap: 5px;
    flex-direction: column;
    padding-left: 0;
  }
  .selected-contract-coverages .collapsible-container-elements .items {
    margin-left: 0;
    padding-left: 0;
  }
  .contract-table-header {
    align-items: baseline;
  }
  .selected-contract-tabs
    .selected-contract-coverages
    .coverage-table:not(.extra-column)
    b.hidden {
    display: block;
  }

  .selected-contract-tabs .selected-contract-coverages b.hidden {
    display: block;
  }

  .collapsible-table-contract-container
    .collapsible-container-elements
    .item:nth-child(n + 3)
    span {
    padding-left: 0;
  }

  .selected-contract-tabs
    .selected-contract-coverages
    > .coverage-table:not(.extra-column) {
    table-layout: initial;
  }

  .collapsible-table-contract-container
    .collapsible-container-elements
    .item:nth-child(2) {
    padding-left: 0;
  }

  .collapsible-table-contract-container:nth-child(2)
    [data-testid="Collapsible"] {
    border-top: 2px solid #f1edeb;
  }

  .collapsible-children-contract {
    background-color: #f8f6f5;
    padding: 0.5rem 1rem 1rem 1rem;
  }

  .responsive-hide {
    display: none;
  }
}

@media (max-width: 319.98px) {
  .selected-contract-tabs
    .selected-contract-coverages
    td.collapse-inside-table {
    padding: 1rem 0;
  }
}

.selected-contract-tabs
  .selected-contract-coverages
  .collapse-inside-table
  .special-terms-name {
  border-bottom: 2px solid #f1edeb;
  margin-top: 10px;
  margin-bottom: 10px;
}
