#dashboard-body {
  position: relative;
  font-family: Calibri, Arial, sans-serif;
}

#promo-carousel-large {
  position: absolute;
  height: 361px;
  width: 1280px;
  left: calc((100vw - 1280px) / 2);
  top: -359px;
}

.carousel-container {
  margin-bottom: 20px;
}

@media (min-width: 1280px) {
  #promo-carousel-large {
    left: 0;
    right: 0;
    margin: auto;
  }
}

@media (max-width: 319.98px) {
  #promo-carousel-large {
    height: 198px;
    width: 704px;
    left: calc((100vw - 704px) / 2);
    top: -237px;
  }
}

@media (max-width: 319.98px) {
  #promo-carousel-large.single-slide {
    height: 198px;
    width: 704px;
    left: calc((100vw - 704px) / 2);
    top: -197px;
  }
}

.promo-carousel .carousel-item .nn-container.promo-info {
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.promo-carousel .promo-img-wrapper {
  height: 361px;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 319.98px) {
  .promo-carousel .promo-img-wrapper {
    max-height: 198px;
  }
}

.promo-carousel .carousel-item .promo-info > div {
  max-width: calc(100% - 50px);
  padding: 13px 30px 25px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 56px;
}

@media (min-width: 768px) {
  .promo-carousel .carousel-item .promo-info > div {
    max-width: 50%;
  }
}

@media (max-width: 319.98px) {
  .promo-carousel .carousel-item .promo-info > div {
    max-width: calc(100% - 30px);
    top: 20px;
    padding: 10px;
  }
}

[data-testid="heroTest"] h3 {
  font-size: 37px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  word-break: break-word;
}

@media (max-width: 575.98px) {
  .promo-carousel .carousel-item .promo-info h3 {
    font-size: 21px;
    line-height: 26px;
  }
}

@media (max-width: 319.98px) {
  .promo-carousel .carousel-item .promo-info h3 {
    line-height: 21px;
  }
}

.promo-carousel .carousel-item .promo-info p {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

@media (max-width: 399.98px) {
  .promo-carousel .carousel-item .promo-info p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.promo-carousel .carousel-item .promo-info .see-more {
  display: block;
  width: 168px;
  height: 38px;
  line-height: 38px;
  border-radius: 3px;
  background: #ea650d;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
}

@media (max-width: 319.98px) {
  .promo-carousel .carousel-item .promo-info .see-more {
    margin: auto;
  }
}

.promo-carousel .nn-container.promo-controls {
  width: 100vw;
  position: absolute;
  height: 361px;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

@media (min-width: 576px) {
  .promo-carousel .nn-container.promo-controls {
    width: auto;
  }
}

@media (max-width: 319.98px) {
  .promo-carousel .nn-container.promo-controls {
    height: 198px;
  }
}

.promo-carousel .carousel-control-next,
.promo-carousel .carousel-control-prev {
  width: 10%;
  opacity: 0;
  pointer-events: all;
}

.promo-carousel .carousel-control-next {
  right: 17px;
}

@media (min-width: 1280px) {
  .promo-carousel .carousel-control-next {
    right: 0;
  }
}

.promo-carousel:hover .carousel-control-next,
.promo-carousel:hover .carousel-control-prev {
  opacity: 1;
}

.promo-carousel .carousel-control-img-wrapper {
  width: 46px;
  height: 46px;
  position: absolute;
  bottom: 0;
  margin: auto;
}

@media (max-width: 319.98px) {
  .promo-carousel .carousel-control-img-wrapper {
    bottom: -50px;
  }
}

.promo-carousel .carousel-control-img-wrapper.prev {
  left: 15px;
}

.promo-carousel .carousel-control-img-wrapper.next {
  right: 15px;
}

@media (max-width: 319.98px) {
  .promo-carousel .carousel-control-img-wrapper.next {
    right: 0;
  }
}

.promo-carousel .carousel-indicators {
  bottom: 0;
  pointer-events: none;
}

@media (max-width: 319.98px) {
  .promo-carousel .carousel-indicators {
    bottom: -16px;
  }
}

.promo-carousel .carousel-indicators li {
  width: 7px;
  height: 7px;
  background-color: #999;
  border: none;
  border-radius: 7px;
  position: relative;
  top: 0;
  pointer-events: all;
}

@media (max-width: 319.98px) {
  .promo-carousel .carousel-indicators li {
    top: 22px;
  }
}

#dashboard-body #promo-carousel-large + .clear {
  height: 1px;
  margin-top: 359px;
}

@media (max-width: 319.98px) {
  #dashboard-body #promo-carousel-large + .clear {
    margin-top: 237px;
  }
}

@media (max-width: 319.98px) {
  #dashboard-body #promo-carousel-large + .clear.single-slide {
    margin-top: 197px;
  }
}

#dashboard-body .main .clear {
  height: 1px;
}

#dashboard-body .nn-container.main {
  position: relative;
}

#dashboard-body .dashboard-alerts .dashboard-alert {
  line-height: 1;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: #f1edeb;
  color: #ea650d;
  letter-spacing: 0;
  display: flex;
  justify-content: space-between;
}

#dashboard-body .dashboard-alerts .dashboard-alert:first-child {
  margin-top: 10px;
}

#dashboard-body .dashboard-alerts .text {
  text-align: left;
  padding: 11px 0 11px 22px;
  max-width: calc(100% - 50px);
  line-height: 19px;
}

#dashboard-body .dashboard-alerts .text span {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

#dashboard-body .dashboard-alerts .text a {
  font-size: 14px;
  text-decoration: underline;
  display: inline-block;
  color: #ea650d;
}

#dashboard-body .dashboard-alerts .remove {
  /* width: 50px;
  position: relative;
  cursor: pointer; */
}

#dashboard-body .dashboard-alerts .remove span {
  /* display: block;
  height: 2px;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ea650d; */
}

/* #dashboard-body .dashboard-alerts .remove span:first-child {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#dashboard-body .dashboard-alerts .remove span:last-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
} */

#dashboard-body .main-content {
  margin-top: 35px;
  display: block;
}

@media (max-width: 319.98px) {
  #dashboard-body .main-content {
    margin-top: 10px;
  }
}

#dashboard-body .main-content .left {
  width: 100%;
}

@media (min-width: 768px) {
  #dashboard-body .main-content {
    display: flex;
    justify-content: space-between;
  }

  #dashboard-body .main-content .left {
    width: calc(70% - 100px);
  }
}

#dashboard-body .main-content .left .contracts {
  margin-bottom: 55px;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .left .contracts {
    margin-bottom: 80px;
    position: relative;
  }
}

#dashboard-body .main-content .left .pending-payments {
  margin-bottom: 25px;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .left .pending-payments {
    margin-bottom: 80px;
    position: relative;
  }
}

#dashboard-body .main-content .header {
  display: flex;
  justify-content: space-between;
  height: 35px;
  line-height: 35px;
  color: #ee7f00;
  font-weight: bold;
  letter-spacing: 0;
}

#dashboard-body .main-content .header .title {
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

@media (max-width: 575.98px) {
  #dashboard-body .main-content .header .title {
    font-size: 21px;
  }
}

#dashboard-body .main-content .header .see-all {
  font-size: 16px;
  text-decoration: none;
  color: #ee7f00;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .header .see-all {
    position: absolute;
    bottom: -50px;
  }
}

#dashboard-body .main-content .body .item {
  border-top: 1px solid #cac7c7;
  padding: 20px 0px 25px 0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .body .item {
    display: block;
  }
}

#dashboard-body .main-content .body .item:last-child {
  padding-bottom: 0;
}

#dashboard-body .main-content .body .item > div {
  width: 50%;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .body .item > div {
    width: 100%;
  }
}

#dashboard-body .main-content .body .item .extra-info {
  text-align: right;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .body .item .extra-info {
    text-align: justify;
    margin: 20px 0 10px 0;
  }
}

#dashboard-body .main-content .body .item > div > span {
  display: block;
  letter-spacing: 0;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .body .item > div > span {
    display: flex;
    justify-content: space-between;
  }
}

#dashboard-body .main-content .body .item-title {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
}

#dashboard-body .main-content .body .item-price {
  color: #414141;
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
  padding: 10px 0 0px 0;
}

#dashboard-body .main-content .body .item-expl {
  color: #7f7f7f;
  font-size: 16px;
}

#dashboard-body .main-content .body .item-number {
  margin-bottom: 5px;
}

#dashboard-body .main-content .body .item-number,
#dashboard-body .main-content .body .item-payment {
  font-size: 16px;
  text-align: right;
  letter-spacing: 0;
}

#dashboard-body .main-content .body .item-number span:first-child,
#dashboard-body .main-content .body .item-payment span:first-child {
  color: #7f7f7f;
}

#dashboard-body .main-content .body .item-number span:last-child,
#dashboard-body .main-content .body .item-payment span:last-child {
  color: #414141;
  font-weight: bold;
}

#dashboard-body .main-content .pending-payments .body .item-payment span {
  color: #ee7f00;
}

#dashboard-body .main-content .body .item-learn-more {
  text-decoration: none;
  color: #414141;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: right;
  margin-top: 15px;
  display: inline-block;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .body .item-learn-more {
    margin-top: 25px;
  }
}

#dashboard-body .main-content .body .item-learn-more > div {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

#dashboard-body .main-content .body .item-learn-more img {
  display: block;
  padding: 4px 7px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .body .item-learn-more img {
    padding: 4px 0px;
  }
}

#dashboard-body .main-content .body .item-learn-more span {
  display: block;
  padding-left: 32px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  color: #414141;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .body .item-learn-more span {
    padding-left: 25px;
  }
}

#dashboard-body .main-content .right {
  width: 100%;
}

@media (min-width: 768px) {
  #dashboard-body .main-content .right {
    width: 30%;
  }
}

#dashboard-body .main-content .right h1 {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}

@media (max-width: 575.98px) {
  #dashboard-body .main-content .right h1 {
    font-size: 24px;
    line-height: 30px;
  }
}

#dashboard-body .main-content .right p {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 10px;
}

#dashboard-body .main-content .right a {
  display: block;
  width: 168px;
  height: 38px;
  line-height: 38px;
  border-radius: 3px;
  background: #ea650d;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
  margin-top: 20px;
}

#dashboard-body .main-content .right .master-visa img {
  width: max-content;
  display: inline;
  padding-top: 15px;
}

@media (max-width: 399.98px) {
  #dashboard-body .main-content .right a {
    width: 100%;
  }
}
/* test */
#dashboard-body .new {
  width: 100%;
  padding-bottom: 20px;
}

/* @media (min-width: 768px) {
  #dashboard-body .new {
    width: 30%;
  }
} */

#dashboard-body .new h1 {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  /* background-color: aqua; */
}

#dashboard-body .new .main {
  display: flex;
  margin-top: 58px;
  margin-bottom: 15px;
  /* background-color: red; */
}
#dashboard-body .new .main2 {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
}

#dashboard-body .new .main .text-buttons {
  margin-left: 30px;
}

#dashboard-body .new p {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

#dashboard-body .new a {
  display: block;
  width: 168px;
  height: 38px;
  line-height: 38px;
  border-radius: 3px;
  background: #ea650d;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
  margin-top: 20px;
}

#dashboard-body .new .online-button {
  float: right;
}

#dashboard-body .new .master-visa img {
  width: max-content;
  display: inline;
  padding-top: 15px;
}

@media (max-width: 575.98px) {
  #dashboard-body .new .main1 h1 {
    font-size: 24px;
    line-height: 30px;
  }
  #dashboard-body .new .main .text-buttons {
    margin-left: 0px;
  }
  #dashboard-body .new .main {
    display: initial;
    margin-top: 20px;
  }
}
