@keyframes rightpagein {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

@keyframes rightpageout {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}

.side-menu-icon {
  font-size: 30px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding-left: 20px;
}

@media (min-width: 768px) {
  .side-menu-icon:not(.group-policies-exist) {
    display: none;
  }
}

@media (max-width: 319.98px) {
	.side-menu-icon {
    padding-left: 0;
  }
}

#side-menu {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  right: -100%;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 10px;
  z-index: 120;
}

#side-menu.active {
  animation-duration: 0.5s;
  animation-name: rightpagein;
  right: 0;
}

#side-menu.inactive {
  animation-duration: 0.5s;
  animation-name: rightpageout;
  right: -100%;
}

#side-menu > div:first-child {
  text-align: right;
}

#side-menu .side-menu-content {
  max-width: 270px;
}

#side-menu .online-payment {
  margin: 20px auto;
  text-align: center;
}

#side-menu .closebtn {
  font-size: 50px;
  line-height: 1;
}

@media (max-width: 575.98px) {
	#side-menu .closebtn {
    font-size: 50px;
    line-height: 0.7;
    display: inline-block;
  }
}

#side-menu .closebtn:hover {
  text-decoration: none;
  color: #ea650d;
}

#side-menu .login-info-wrapper {
  text-align: center;
}

#side-menu .login {
  margin: auto;
  text-align: center;
  display: inline-block;
}

#side-menu .login .login-info {
  width: calc( 100% - 70px );
}

#side-menu .navigation {
  border-top: 1px solid #cac7c7;
}

#side-menu .navigation a {
  margin: 20px auto 30px;
  font-size: 19px;
  position: relative;
}
