#inbox-table {
  margin-top: 15px;
  border-top: 2px solid #dee2e6;
}

#inbox-table.active {
  margin-top: 7px;
}

#inbox-table th,
#inbox-table td {
  border-top: none !important;
  color: #414141;
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  border-bottom: 2px solid #dee2e6;
}

#inbox-table td {
  padding: 11px 5px 13px 27px !important;
  vertical-align: middle;
}

#inbox-table td .checkBox-container {
  display: flex;
  align-items: center;
}

#inbox-table td:last-child {
  text-align: right;
}

#inbox-table th {
  padding: 0 1.2rem !important;
}

#inbox-table td span {
  font-weight: normal;
}

#inbox-table tbody tr td:nth-child(2) {
  cursor: pointer;
}

#inbox-table tbody tr:hover {
  background-color: #f1edeb;
  box-shadow: inset 0 -2px 0 0 #e5ded9, inset 0 2px 0 0 #e5ded9;
}

#inbox-table .title {
  color: #ee7f09;
  display: block;
  position: relative;
  line-height: 19px;
  margin-top: 2px;
}

#inbox-table .title img {
  position: absolute;
  top: 11px;
  left: -25px;
}

#inbox-table .highlighted {
  font-weight: bold;
}

#inbox-table .datetime {
  display: block;
  line-height: 19px;
  padding: 0 8px;
  margin-bottom: 1px;
}

#inbox-table .sm {
  display: none;
}

#inbox-table .category {
  background-color: #e5ded9;
  display: inline-block;
  padding: 2px 8px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
  letter-spacing: 0;
}

#inbox-table [data-testid="Circle"] {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  top: 11px;
  left: -25px;
}

#inbox-table .title-name-container {
  width: 595px;
}

@media (max-width: 575.98px) {
  #inbox-table th {
    border: none !important;
  }

  #inbox-table tr {
    border-bottom: 2px solid #dee2e6;
    display: block;
  }

  #inbox-table td {
    padding: 11px 25px 13px !important;
    width: 100%;
    display: block;
    border-bottom: none !important;
  }

  #inbox-table td:nth-child(3),
  #inbox-table td:last-child,
  #inbox-table .datetime,
  #inbox-table .category {
    display: none;
  }

  #inbox-table .sm {
    display: block;
  }

  #inbox-table .sm .datetime {
    display: inline-block;
    width: 100%;
  }

  #inbox-table .sm .category {
    display: inline-block;
  }

  #inbox-table .title-name-container {
    width: 200px;
  }
}
