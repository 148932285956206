.analysisChartSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    flex-wrap: wrap;
    gap: 20px;

    .analysisChartElement {
        flex-basis: 0;
        padding-top: 15px;
        flex-grow: 1;
        flex-shrink: 1;
        background-color: #F8F6F5;
        min-width: 351px;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 451px;
    }
}

.analysisPriceHistoryModal {
    overflow: hidden;
    height: 100%;

    .header {
        h4 {
            color: #EA650D !important;
        }
    }

    .note {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        label {
            color: #EA650D !important;
            font-weight: unset;
        }        
    }
}

.noOutline {
    outline: none !important;

    * {
        outline: none !important;
    }
}

.analysisChartSeparator {
    border-width: 0;
    border-top-width: 2px;
    border-style: solid;
    border-color: #EA650D;
    margin-top: 10px;
    width: 100%;
}

.chartDisclaimer {
    color: black;
    display: flex;
    flex-direction: row;
}

.chartDisclaimerAnalysis {
    color: black;
    display: flex;
    flex-direction: column;
}

.mobileTotalsCard {
    padding-top: 20px;
}

.listContainer {
    border-top: 1px solid #e5ded9;
    padding: 15px 0;

    &:not(:last-child) {
        border-bottom: 1px solid #e5ded9;
    }
}