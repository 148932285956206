.contracts .search-contracts-label {
  float: left;
  margin-top: 10px;
  position: relative;
}

@media (max-width: 767.98px) {
	.contracts .search-contracts-label {
    float: none;
  }
}

@media (max-width: 319.98px) {
	.contracts .search-contracts-label {
    width: 100%;
  }
}

.contracts #search-contracts {
  box-sizing: border-box;
  height: 35px;
  width: 282px;
  border: 1px solid #E5DED9;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 33px;
  font-size: 16px;
}

.contracts #search-contracts::placeholder {
  color: #999999;
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
}

@media (max-width: 319.98px) {
	.contracts #search-contracts {
    width: 100%;
    font-size: 14px;
  }

  .contracts #search-contracts::placeholder {
    font-size: 14px;
  }
}

.contracts #search-contracts:focus {
  outline: none;
}

.contracts .search-icon {
  position: absolute;
  right: 2px;
  top: 2px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  left: auto;
}

.contracts .search-icon:focus {
  outline: none;
}

.contracts .filter-contracts {
  float: right;
  margin-top: 17px;
}

@media (max-width: 767.98px) {
	.contracts .filter-contracts {
    float: none;
  }
}

.contracts #active-contracts,
.contracts #all-contracts {
  display: none;
}

.contracts #active-contracts + label:before,
.contracts #all-contracts + label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 7px;
  margin: 0 7px 0 0;
  height: 25px;
  width: 25px;
  border: 1px solid #E5DED9;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.contracts #active-contracts:checked + label:after,
.contracts #all-contracts:checked + label:after {
  content: " ";
  display: block;
  position: absolute;
  top: 13px;
  left: 6px;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  background-color: #EA650D;
}

.contracts .active-contracts-label,
.contracts .all-contracts-label {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  top: -7px;
}

.contracts .active-contracts-label {
  margin-right: 35px;
}