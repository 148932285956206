#payments-table {
  margin-bottom: 40px;
  margin-top: 40px;
}

#payments-table b.hidden {
  display: none;
}

#payments-table .table {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 30px;
}

#payments-table table.table-inside {
  margin-bottom: 0;
}

#payments-table .table div span {
  font-weight: normal;
}

#payments-table .expand {
  background-color: #f8f6f5;
}

#payments-table .documents-collapse a.active {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

#payments-table .clarification {
  color: #bcbcbc;
  font-size: 16px;
  letter-spacing: 0;
}

.payments-table-header {
  width: 950px;
  display: grid;
  grid-template-columns: 340px 170px 190px 170px;
  margin-bottom: 10px;
  padding-left: 75px;
}

.payments-table-header [data-testid="Label"] {
  display: flex;
  align-items: center;
}
.payments-table-header [data-testid="Label"]:nth-child(3) {
  padding-left: 25px;
}
.payments-table-header [data-testid="Label"]:nth-child(4) {
  padding-left: 5px;
}

#payments-table [data-testid="Table"] {
  background-color: #f8f6f5;
  padding: 0 20px;
}
#payments-table [data-testid="Table"] .MuiDataGrid-footerContainer {
  display: none;
}

#payments-table [data-testid="Collapsible"] [data-testid="Label"] {
  width: 900px;
  padding: 0 25px;
}

[data-testid="Collapsible"] .MuiAccordionSummary-expandIconWrapper {
  display: none;
}

.collapsible-container {
  width: 100%;
  display: flex;
}

#payments-table .collapsible-container .item {
  flex-basis: 0%;
  flex-grow: 1;
  text-align: left;
  margin: auto;
}
#payments-table .collapsible-container .item:first-child {
  flex-grow: 2;
}

#payments-table [data-testid="Table"] .MuiDataGrid-columnHeaders {
  border-bottom-width: 0 !important;
}

.code-payment-container {
  display: flex;
  flex-direction: column;
}

#payments-table .collapsible-container-elements {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-left: 25px;
}
#payments-table .collapsible-container-elements .item:nth-of-type(3) span {
  padding-left: 25px;
}
#payments-table .collapsible-container-elements .item:last-child span {
  padding-left: 25px;
}
#payments-table {
  flex-basis: 100%;
  text-align: start;
}
#payments-table .MuiDataGrid-columnHeaderTitle:nth-child(1) {
  text-align: start;
}

@media (max-width: 990.98px) {
  .payments-table-header {
    padding-left: 45px;
    grid-template-columns: 265px 120px 155px 170px;
  }

  #payments-table [data-testid="Collapsible"] [data-testid="Label"] {
    max-width: 705px;
    padding-right: 0;
  }
  #payments-table .collapsible-container-elements {
    padding-left: 10px;
  }
  #payments-table .collapsible-container-elements .item:first-child {
    flex-grow: 1.9;
  }
  #payments-table .collapsible-container-elements .item:nth-of-type(2) span {
    padding-left: 4px;
  }
  #payments-table .collapsible-container-elements .item:nth-of-type(3) span {
    padding-left: 10px;
  }
  #payments-table .collapsible-container-elements .item:last-child span {
    padding-left: 5px;
  }
}

@media (max-width: 767.98px) {
  #payments-table b.hidden {
    display: block;
  }

  .collapsible-container {
    display: flex;
    flex-direction: row;
    width: 500px;
  }

  .collapsible-container-elements {
    display: flex;
    gap: 10px;
  }

  #payments-table .collapsible-container .item:first-child {
    flex-grow: 1;
  }

  #payments-table [data-testid="Collapsible"] [data-testid="Label"] {
    cursor: pointer !important;
    display: flex;
    max-width: 300px !important;
    padding-left: 10px;
  }

  .payments-table-header {
    display: none;
  }

  .collapsible-container-elements {
    padding-left: 20px;
  }

  .collapsible-container-elements .item {
    padding-bottom: 5px;
  }

  #payments-table .collapsible-container-elements .item:nth-of-type(2) span {
    padding-left: 0;
  }
  #payments-table .collapsible-container-elements .item:nth-of-type(3) span {
    padding-left: 0;
  }
  #payments-table .collapsible-container-elements .item:last-child span {
    padding-left: 0;
  }
  #payments-table .collapsible-container .chevron-down {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 399.98px) {
  .collapsible-container-elements {
    flex-direction: column;
  }
  #payments-table .collapsible-container .item {
    margin: 0;
  }
}
