.pageThemeHeaderButtonWrapper-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 35px;
}

.pageThemeHeaderButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 635px;
}

.pageThemeHeaderButtonWrapper + button {
  background-color: #ea650d !important;
}

@media (min-width: 768px) {
  .pageThemeHeaderButtonWrapper-container {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .pageThemeHeaderButtonWrapper + button {
    margin-top: 35px;
  }
}
