#chat-container {
	position: fixed;
	bottom: 20px;
	right: 20px;
	font-family: Calibri, Arial, sans-serif;
	z-index: 100;
}

@media (max-width: 319.98px) {
	#chat-container {
		bottom: 40px;
		right: 10px;
	}
}

.chat-content {
	display: none;
	background-color: #F8F6F5;
	height: fit-content;
	width: calc(100vw - 20px);
	max-width: 400px;
	border-radius: 4px;
	padding: 15px;
	max-height: 551px;
}

.chat-content.active {
	display: block;
}

.chat-content .page {
	border: 1px solid #CAC7C7;
	border-radius: 4px;
	background-color: #FFFFFF;
	height: 100%;
	width: 100%;
	display: none;
	overflow-y: auto;
}

.chat-content .callmeback-container {
	padding: 20px 30px;
	height: calc(100% - 61px);
	overflow-y: auto;
}

.chat-content .callmeback-container>div {
	margin-top: 15px;
	margin-bottom: 15px;
}

.chat-content .callmeback-container>div:first-child {
	margin-top: 0;
}

.chat-content .callmeback-container .prefixed {
	position: 250px;
}

.chat-content .callmeback-container .prefixed span {
	display: none;
	position: absolute;
	bottom: 3px;
	left: 10px;
}

.chat-content .callmeback-container .prefixed.focused span {
	display: block;
}

/* .chat-content .callmeback-container .prefixed.focused input {
	padding-left: 45px;
} */

.chat-content .page.active {
	display: block;
}


.chat-content .first-page .greeting {
	padding: 16px 29px;
}

.chat-content .first-page .greeting span {
	display: block;
	text-align: left;
	font-weight: bold;
	letter-spacing: 0;
}

.chat-content .first-page .greeting .title {
	font-size: 24px;
	color: #EA650D;
	line-height: 1;
}

.chat-content .first-page .greeting .body {
	font-size: 18px;
}

.chat-content .first-page .actions {
	padding: 0 29px 16px;
}

.chat-content .first-page .actions ul {
	list-style: none;
	padding: 0;
}

.chat-content .first-page .actions li {
	line-height: 37px;
	margin: 17px 0;
}

.chat-content .first-page .actions a {
	display: flex;
	text-decoration: none;
}

.chat-content .first-page .actions a>div {
	display: inline-block;
	width: 37px;
	height: 37px;
	text-align: center;
	line-height: 37px;
	position: relative;
	vertical-align: top;
}

.chat-content .first-page .actions img {
	position: absolute;
	left: 0;
	margin: auto;
	top: 50%;
	transform: translateY(-50%);
}

/* .chat-content .first-page .actions span {
	display: inline-block;
    margin-left: 17px;
    margin-top: 10px;
    color: #414141;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
    width: calc( 100% - 37px );
} */

.actions .classspan {
	display: inline-block;
	margin-left: -10px;
	margin-top: 5px;
	color: #414141;
	font-size: 18px;
	letter-spacing: 0;
	line-height: 18px;
	width: calc(100% - 37px);
}

.actions .old {
	display: inline-block;
	margin-left: 17px;
	margin-top: 10px;
	color: #414141;
	font-size: 18px;
	letter-spacing: 0;
	line-height: 18px;
	width: calc(100% - 37px);
}

.tab-button {
	display: inline-block;
	margin-top: 15px;
	letter-spacing: 0;
	width: calc(100% - 37px);
	height: 38px;
	line-height: 38px;
	border-radius: 3px;
	background: #f1edeb;
	color: #4a4a4a;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	padding: 0 20px;
	vertical-align: top;
}

.tab-button.active {
	background: #ea650d;
	color: #fff;
}

.tab-container {
	padding: 16px 29px;
}

.header-container {
	display: inline-flex;
	align-items: center;
}

.chat-content .chat-nav {
	height: 61px;
	border-bottom: 1px solid #E5DED9;
}

.chat-content .chat-nav>div {
	display: inline-flex;
	padding: 0 29px;
	height: 30px;
	line-height: 30px;
	vertical-align: top;
	margin-top: 15px;
}

@media (max-width: 399.98px) {
	.chat-content .chat-nav>div {
		padding: 0 25px;
	}
}

@media (max-width: 319.98px) {
	.chat-content .chat-nav>div:first-child {
		padding: 0 20px 0 25px;
	}

	.chat-content .chat-nav>div:last-child {
		padding: 0 25px 0 0;
	}
}

@media (max-width: 250px) {
	.chat-content .chat-nav>div:first-child {
		padding: 0 10px 0 25px;
	}
}

.chat-content .chat-nav .left-caret-mock {
	position: relative;
	width: 15px;
	height: 30px;
	cursor: pointer;
}

.chat-content .chat-nav .left-caret-mock div {
	height: 12px;
	width: 2px;
	position: absolute;
	background-color: #999999;
	left: 5px;
}

.chat-content .chat-nav .left-caret-mock div:first-child {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: 14px;
}

.chat-content .chat-nav .left-caret-mock div:last-child {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
	top: 6px;
}

.chat-content .chat-nav img {
	display: inline-block;
	margin-right: 20px;
	vertical-align: top;
}

.chat-content .chat-nav span {
	display: inline-block;
	color: #414141;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
}

@media (max-width: 319.98px) {
	.chat-content .chat-nav img {
		margin-right: 10px;
		width: 20px;
		margin-top: 5px;
	}

	.chat-content .chat-nav span {
		font-size: 16px;
	}
}

.chat-content .second-page .chat-iframe-container {
	height: calc(100% - 61px);
}

.chat-content .second-page iframe {
	border: 0;
	max-height: 100%;
}

.chat-content .third-page label {
	display: block;
	font-weight: bold;
	margin-bottom: 0;
	float: left;
}

.chat-content #commentsNum {
	float: right;
}

.chat-content #callmeback-mobile-phone {
	width: 100%;
}

.chat-content #callmeback-comments {
	width: 100%;
}

.chat-content #emailme-email-address {
	width: 100%;
}

.chat-content #emailme-comments {
	width: 100%;
}

.chat-content .comments {
	word-break: break-word;
}

.chat-content .fourth-page .success {
	font-size: 20px;
	color: #696969;
	position: relative;
	line-height: 24px;
	font-weight: bold;
	display: flex;
	justify-content: start;

}

.chat-content .fourth-page .success span {
	width: 40px;
	height: 40px;

}

.check-icon {
	margin-right: 20px;
}

#chat-container .chat-toggler {
	margin-top: 20px;
	text-align: right;
	display: none;
	height: 51px;
	line-height: 51px;
}

#chat-container .chat-toggler.active {
	display: block;
}

#chat-container .chat-toggler>div {
	/* background-color: #EA650D;
	border-radius: 25.5px; */
	display: inline-block;
	/* text-align: center;
	color: #fff;
	font-size: 18px;
    font-weight: bold;
	letter-spacing: 0;
	cursor: pointer; */
}

#chat-container .chat-toggler-inactive>div {
	height: 100%;
	width: 136px;
}

#chat-container .chat-toggler-active>div {
	height: 100%;
	position: relative;
	width: 51px;
}

/* #chat-container .chat-toggler-active span {
	background-color :#fff;
	height: 21px;
	width: 4px;
	display: block;
	position: absolute;
	top: 15px;
	left: 23.5px;
	border-radius: 4px;
}

#chat-container .chat-toggler-active span:first-child {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
}

#chat-container .chat-toggler-active span:last-child {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
} */

.temp-chat-coming-soon {
	text-align: center;
	font-size: 16px;
}

.temp-chat-coming-soon span {
	color: #EE7F00;
	font-size: 48px;
	font-weight: bold;
	letter-spacing: 0;
	margin-bottom: 15px;
	display: block;
}

.temp-chat-coming-soon a {
	width: 120px;
}

.chat-toggler [data-testid=Button] {
	border-radius: 25.5px !important;
}

.chat-toggler-active [data-testid=IconButton] {
	text-align: center;
	background-color:
		#EA650D !important;
}

.chat-toggler-active [data-testid=IconButton]:hover {
	text-align: center;
	background-color:
		#E64415 !important;
}

#callmeback {
	margin-left: 10px;
}

#emailme {
	margin-left: 10px;
}

.error-input {
	color: red;
}