.my-inbox-label {
  color: #ee7f00;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 41px;
  margin-bottom: 15px;
  margin-top: 35px;
}

.inbox-top-wrapper {
  position: relative;
}

/* custom radio button */
.filter-wrapper {
  position: relative;
  text-align: right;
  height: 0;
}

.filter-messages {
  position: absolute;
  right: 0;
  top: -45px;
}

.search-category-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.search-container > div,
.category-container > div {
  width: 300px;
}

.category-container > div > div {
  margin-top: 0px;
}

#active-messages,
#archived-messages {
  display: none;
}

#active-messages + label:before,
#archived-messages + label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 7px;
  margin: 0 7px 0 0;
  height: 25px;
  width: 25px;
  border: 1px solid #e5ded9;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

#active-messages:checked + label:after,
#archived-messages:checked + label:after {
  content: " ";
  display: block;
  position: absolute;
  top: 13px;
  left: 6px;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  background-color: #ea650d;
}

.active-messages-label,
.archived-messages-label {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  top: -7px;
}

.active-messages-label {
  margin-right: 35px;
}

/* options toggler start */
.filter-toggle {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: none;
}

.filter-toggle.active {
  display: inherit;
}

/* search input start */

#search-inbox {
  box-sizing: border-box;
  height: 35px;
  width: 282px;
  border: 1px solid #e5ded9;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 33px;
  font-size: 16px;
}

#search-inbox::placeholder {
  color: #999999;
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
}

#search-inbox:focus {
  outline: none;
}

.search-icon {
  position: absolute;
  right: 2px;
  height: 32px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  pointer-events: none;
}

.search-icon:focus {
  outline: none;
}

/* pagination start */

.pagination-nav {
  display: inline-block;
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}

.pages-text {
  display: inline-block;
}

.pages-text span {
  display: block;
  color: #757575;
  font-family: Calibri, Arial, sans-serif;
  letter-spacing: 0;
  text-align: right;
}

/* filter options start */
#filter-options {
  display: none;
}

#filter-options.active {
  display: block;
  padding: 15px 0;
  clear: both;
}

#filter-options > span {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

#filter-options > div {
  padding-top: 15px;
}

/* #filter-options input {
  display: none;
} */

.checkboxgroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

#filter-options label {
  display: inline-block;
  width: 238px;
  margin: 0 0 20px 0;
  cursor: pointer;
}

#filter-options label span {
  padding-left: 8px;
  margin: 0;
  font-size: 16px;
  color: #5e5e5e;
}

#filter-options .hidden {
  display: none;
}

.inbox-table-footer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.inbox-table-footer .archive-button {
  width: 100px;
}

@media (max-width: 767.98px) {
  .filter-wrapper {
    text-align: left;
    height: auto;
  }

  .search-container,
  .category-container {
    height: 53px;
  }

  .filter-messages {
    position: initial;
    right: auto;
    top: auto;
  }

  .search-category-container,
  .inbox-table-footer {
    flex-direction: column;
  }

  .search-container > div,
  .category-container > div {
    width: calc(100% - 49px);
  }

  #search-inbox {
    width: 100%;
  }
}

@media (max-width: 319.98px) {
  #search-inbox {
    font-size: 14px;
  }

  #search-inbox::placeholder {
    font-size: 14px;
  }
}
