.info-kyc {
  max-width: 565px;
}

.kyc-customer-status {
  display: flex;
  gap: 20px;
}

.kyc-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.information-container-kyc {
  display: flex;
  margin-top: 32px;
  gap: 32px;
  max-width: 647px;
}

.center-items {
  align-items: center;
}

.checkbox-container label {
  max-width: 565px;
  white-space: pre-line;
}

.kyc-step2-bottom-container {
  display: flex;
  justify-content: space-around;
  margin-top: 32px;
}
.kyc-step2-bottom-container div {
  display: flex;
  align-items: center;
  gap: 32px;
}

.card-kyc-container {
  padding: 28px 16px;
}

.kyc-btn button span {
  white-space: normal;
}

.radiogroup_kyc [data-testid="Label"] {
  margin: 0 !important;
  white-space: break-spaces;
}
