.selected-contract-tabs .selected-contract-account-pension,
.selected-contract-tabs .selected-contract-account-investment {
  margin-top: 20px;
}

.selected-contract-tabs .selected-contract-account-pension > div,
.selected-contract-tabs .selected-contract-account-investment > div {
  display: inline-block;
  width: 30%;
  margin-left: 3%;
  vertical-align: top;
  word-break: break-word;
}

@media (max-width: 599.72px) {
  .selected-contract-tabs .selected-contract-account-pension > div,
  .selected-contract-tabs .selected-contract-account-investment > div {
    width: 100%;
    margin-left: 0%;
    margin-bottom: 20px;
  }
}

.selected-contract-tabs .account-value div,
.selected-contract-tabs .account-mortgage div,
.selected-contract-tabs .account-document div {
  margin-bottom: 17px;
}

.selected-contract-tabs .account-oseka div {
  margin: 10px 0;
}

.selected-contract-tabs .account-value p,
.selected-contract-tabs .account-mortgage p,
.selected-contract-tabs .account-oseka p,
.selected-contract-tabs .account-document p {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.selected-contract-tabs .selected-contract-account-pension .account-oseka {
  margin-bottom: 30px !important;
}

.selected-contract-tabs .account-oseka p {
  line-height: 20px;
}

.selected-contract-tabs .account-value span,
.selected-contract-tabs .account-mortgage span,
.selected-contract-tabs .account-document span {
  color: #414141;
  font-size: 16px;
  display: block;
  margin-left: 0px;
  margin-top: -3px;
}

.selected-contract-tabs .account-document > span {
  margin-top: 5px;
  display: flex !important;
  align-items: center;
  width: 100%;
}

.selected-contract-tabs .account-document a {
  margin-left: 5px;
}

.selected-contract-tabs .account-value .takeover-value {
  color: #999999;
  font-size: 14px;
  display: block;
  margin-top: 0px;
}

.selected-contract-tabs .account-oseka img {
  margin-right: 7px;
}

.selected-contract-tabs .account-oseka a.active {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.selected-contract-tabs .account-document img {
  margin-left: 3px;
  margin-bottom: 9px;
}
.selected-contract-tabs .account-document a.active {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.selected-contract-tabs .account-table {
  position: relative;
  top: 32px;
}

.contract-selected-document-table [role="row"] > div:first-child {
  color: #ea650d;
}

.selected-contract-tabs .account-table .collapsing {
  transition: none !important;
}

.selected-contract-tabs .account-table b.hidden {
  display: none;
}

.selected-contract-tabs .selected-contract-account-investment .chevron-link,
.selected-contract-tabs .selected-contract-account-pension .chevron-link {
  width: 100%;
}
.selected-contract-tabs .selected-contract-account-investment .chevron-link a > span,
.selected-contract-tabs .selected-contract-account-pension .chevron-link a > span {
  padding-left: 0;
  margin-right: 5px;
}
.selected-contract-tabs .selected-contract-account-investment .chevron-link a > div span,
.selected-contract-tabs .selected-contract-account-pension .chevron-link a > div span {
  padding-left: 0;
}

.selected-contract-tabs .selected-contract-account-investment .chevron-link a,
.selected-contract-tabs .selected-contract-account-pension .chevron-link a {
  display: flex;
  align-items: center;
}

@media (max-width: 399.98px) {
  .selected-contract-tabs .account-table b.hidden {
    display: block;
  }
}
