.legend-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.legend-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

#legend-name {
  flex: 1;
}

[data-testid="fund-portfolio-table"] .MuiDataGrid-row--lastVisible {
  background-color: #f3efed !important;
}

.barChartContainer {
  padding: 40px 70px;
}

.barChartTitle {
  font-size: 28px;
  font-weight: 700;
}
