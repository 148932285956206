.header {
    .groupPolicyItem {
        overflow-wrap: break-word;
    }

    label[data-testid="Label"]:first-child {
        grid-column-start: initial;
        grid-column-end: initial;
    }

    div:first-child {
        grid-column-start: initial;
        grid-column-end: initial;
    }

    label[data-testid="Label"]:nth-child(2) {
        grid-column-start: initial;
        grid-column-end: initial;
        margin-left: 0px !important;
    }

    div:nth-child(2) {
        grid-column-start: initial;
        grid-column-end: initial;
        margin-left: 0px !important;
    }

    label[data-testid="Label"]:nth-child(3) {
        margin-left: 0px !important;
    }

    div:nth-child(3) {
        margin-left: 0px !important;
    }

    grid-template-columns: 400px 200px 250px;

}

.groupPolicyCollapsibleChildren {
    padding: 0 0 0 15px !important;
}

.groupPolicyCollapsibleChildren [data-testid="Label"] {
    padding: 0 !important;
    color: initial !important;
    border-bottom: initial !important;
    margin-bottom: initial !important;
}

.numberValue {
    label[data-testid="Label"] {
        font: normal normal normal 24px/36px Calibri;
    }
}

.disclaimer {
    padding: 40px 0 0 40px !important;

    p {
        font-style: italic;
        margin: 0;
    }

    a {
        text-decoration: underline;
        font-weight: bold;
    }

    .lastUpdate {
        padding-right: 33.33px;
        margin-top: 8px;
        
        p {
            margin: 0;            
            text-align: right;
            color: #999999;
        }
    }
}

@media (max-width: 991.98px) {
    .header {
        grid-template-columns: 200px 200px 200px;

        label[data-testid="Label"]:nth-child(2) {
            margin-left: 0px !important;
        }
    
        div:nth-child(2) {
            margin-left: 0px !important;
        }

        label[data-testid="Label"]:nth-child(3) {
            margin-left: 0px !important;
        }
    
        div:nth-child(3) {
            margin-left: 0px !important;
        }
    }
}

@media (max-width: 767.98px) {
    .groupPolicyContainerElements {
        grid-gap: inherit !important;
    }

    .disclaimer {
        padding: 0 0 0 30px !important;
    }
}

@media (max-width: 440px) {
    .groupPolicyItem {
        word-break: break-all !important;
    }
}