body {
  display: block !important;
}

a {
  cursor: pointer;
}

a:focus {
  outline: none;
}

@keyframes leftpageout {
  from {
    right: 0;
  }

  to {
    right: 150px;
  }
}

@keyframes leftpagein {
  from {
    right: 100%;
  }

  to {
    right: 0;
  }
}

#outer-root {
  position: relative;
  right: 0;
  background-color: #fff;
}

#outer-root.sideisactive {
  animation-duration: 0.5s;
  animation-name: leftpageout;
  right: 100%;
  z-index: 119;
}

#outer-root.sideisinactive {
  animation-duration: 0.5s;
  animation-name: leftpagein;
  right: 0;
  z-index: 121;
}

#inner-root {
  max-width: 1280px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: Calibri, Arial, sans-serif;
}

.nn-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .nn-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .nn-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .nn-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .nn-container {
    max-width: 970px;
  }
}

@media (max-width: 399.98px) {
  .container {
    padding: 0 !important;
  }
}

/* start breadcrumbs*/

.breadcrumbs {
  margin-top: 40px;
}

.breadcrumbs a {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
}

.breadcrumbs a:hover {
  text-decoration: none;
  color: #414141;
}

.breadcrumbs .separator {
  color: #414141;
  font-family: "NN Dagny Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.breadcrumbs span {
  color: #999999;
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 499.98px) {
  .breadcrumbs a,
  .breadcrumbs .separator,
  .breadcrumbs span {
    font-size: 14px;
  }
}

@media (max-width: 319.98px) {
  .breadcrumbs a,
  .breadcrumbs .separator,
  .breadcrumbs span {
    font-size: 12px;
  }
}

/* end breadcrumbs*/

/* start links */

.chevron-link {
  text-align: left;
  margin: 27px 0;
  display: inline-block;
}

.chevron-link-icon > a {
  display: flex;
  text-decoration: none;
  line-height: 24px;
  cursor: pointer;
  color: #414141;
  align-items: center;
  gap: 5px;
}

.chevron-link > a,
.chevron-link > .inner {
  display: inline-block;
  position: relative;
  text-decoration: none;
  line-height: 24px;
  cursor: pointer;
}

/* [data-testid="Table"] {
  width: 100% !important;
} */

.chevron-link:hover a {
  text-decoration: underline;
  color: #ea650d;
}

.chevron-link:hover span {
  color: #ea650d;
}

.chevron-link img {
  display: block;
  padding: 4px 7px 4px 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.chevron-link span {
  display: block;
  padding-left: 31px;
  font-size: 14px;
  letter-spacing: 0;
  color: #414141;
}

.chevron-link span a {
  color: #414141;
}

/* end links */

/* start buttons */

.nn-button-normal,
.nn-button-contrast,
.nn-button-gray,
a.nn-button-normal,
a.nn-button-contrast,
a.nn-button-gray {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  border-radius: 3px;
  background: #ea650d;
  color: #fff !important;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
  padding: 0 20px;
  vertical-align: top;
  margin-left: 15px;
}

.nn-button-contrast,
a.nn-button-contrast {
  background: #fff;
  color: #ea650d !important;
  border: 1px solid;
  line-height: 36px;
}

.nn-button-gray,
a.nn-button-gray {
  background: #757575;
  color: #fff !important;
  border: 1px solid;
  line-height: 36px;
  margin-top: 10px;
}

.nn-button-normal:hover,
.nn-button-normal:focus,
.nn-button-gray:hover,
.nn-button-gray:focus,
a.nn-button-normal:hover,
a.nn-button-normal:focus,
a.nn-button-gray:hover,
a.nn-button-gray:focus {
  color: #fff !important;
  text-decoration: none;
  cursor: pointer;
}

.nn-button-contrast:hover,
.nn-button-contrast:focus,
a.nn-button-contrast:hover,
a.nn-button-contrast:focus {
  color: #ea650d !important;
  text-decoration: none;
}

/* end buttons */

/* start text */

.help {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 24px;
}

.text-center {
  text-align: center;
}

.text-21 {
  font-size: 21px;
}

.text-24 {
  font-size: 24px;
}

.text-29 {
  font-size: 29px;
}

/* end text */

/* start colors */

.light {
  color: #ee7f00;
}

.normal {
  color: #ea650d;
}

.dark {
  color: #e64415;
}

.gray {
  color: #757575;
}

.light-gray {
  color: #adadad;
}

.extra-light-gray {
  color: #e5ded9;
}

.extreme-light-gray {
  color: #f8f6f5;
}

.gray-bg {
  background: #757575;
}

.light-gray-bg {
  background: #adadad;
}

.extra-light-gray-bg {
  background: #e5ded9;
}

.extreme-light-gray-bg {
  background: #f8f6f5;
}

/* end colors */

/* start paddings */

.padding-all-20 {
  padding: 20px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-all-40 {
  padding: 40px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

/* end paddings */

/* start modal-coming-soon */

.coming-soon {
  text-align: center;
}

@media (min-width: 576px) {
  .coming-soon {
    margin: 8.75rem auto;
  }
}

.coming-soon .modal-content {
  padding: 0 20px 10px 20px;
}

.coming-soon .modal-content span {
  color: #ee7f00;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 15px;
  display: block;
}

.coming-soon .modal-content a {
  width: 120px;
}

.coming-soon .modal-body {
  font-size: 16px;
}

/* end modal-coming-soon */

/* start modal-disconnect-timeout */

#inner-root #disconnect-timeout {
  text-align: left;
}

@media (min-width: 576px) {
  #inner-root #disconnect-timeout {
    margin: 8.75rem auto;
  }
}

#inner-root #disconnect-timeout .modal-content {
  padding: 20px 30px;
}

#inner-root #disconnect-timeout span {
  color: #ee7f00;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}

#inner-root #disconnect-timeout p {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  padding: 30px 0 20px 0;
  margin: 0;
}

#inner-root #disconnect-timeout .modal-timeout {
  color: #ea650d;
  font-size: 68px;
  letter-spacing: 0;
  line-height: 83px;
  text-align: center;
  display: block;
  margin-bottom: 30px;
}

#inner-root #disconnect-timeout .nn-button-normal {
  width: 168px;
  padding: 0;
  font-weight: bold;
}

#inner-root #disconnect-timeout .nn-button-contrast {
  width: 138px;
  margin-left: 5px;
  padding: 0;
  font-weight: bold;
}

#inner-root #disconnect-timeout .modal-body {
  font-size: 16px;
}

/* end modal-disconnect-timeout */

/* start modal-welcome */

.welcome-popup {
  font-size: 16px;
  font-family: Calibri, Arial, sans-serif;
  text-align: center;
}

@media (min-width: 576px) {
  .welcome-popup {
    margin: 0 auto;
  }

  .welcome-popup .modal-dialog {
    max-width: 850px;
  }
}

.welcome-popup .modal-content {
  padding: 0 20px 10px 20px;
}

.welcome-popup .modal-header {
  display: block;
  border: none;
  padding: 0;
  z-index: 1;
}

.welcome-popup .modal-header .close {
  font-size: 60px;
  color: #a3a2a2;
  outline: none;
  padding: 0;
  margin: 0 0 -40px 0;
  font-weight: normal;
  width: 30px;
}

.welcome-popup .modal-header .close:hover {
  color: #a3a2a2;
}

.welcome-popup h3 {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: 0px;
}

.welcome-popup p {
  color: #545454;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 30px;
  text-align: left;
}

.welcome-popup .icons {
  width: 100%;
  margin: 40px 0;
  text-align: center;
  display: flex;
}

.welcome-popup .icons div {
  display: inline-block;
  width: 150px;
  text-align: center;
  margin: 10px auto;
}

.welcome-popup .icons img {
  display: block;
  margin: auto auto 5px;
}

.welcome-popup .nn-button-normal {
  margin-bottom: 20px;
}

/* end modal-welcome */

/* Start notePopUp*/
.terms-popup {
  text-align: center;
  font-family: Calibri, Arial, sans-serif;
}

.terms-checkbox-container {
  display: flex;
  justify-content: space-around;
}
.terms-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (min-width: 576px) {
  .terms-popup {
    margin: 0 auto;
  }

  .terms-popup .modal-dialog {
    max-width: 850px;
  }
}

.terms-popup .modal-content {
  padding: 0 20px 10px 20px;
}

.terms-popup .modal-header {
  display: block;
  border: none;
  padding: 0;
  z-index: 1;
}

.terms-popup .modal-header .close {
  font-size: 60px;
  color: #a3a2a2;
  outline: none;
  padding: 0;
  margin: 0 0 -40px 0;
  font-weight: normal;
  width: 30px;
}

.terms-popup .modal-header .close:hover {
  color: #a3a2a2;
}

.terms-popup .modal-body {
  font-size: 16px;
}

.terms-popup h3 {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 44px;
}

.terms-popup p {
  color: #545454;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 30px;
  text-align: left;
}

.terms-popup .modal-body .icons {
  width: 100%;
  margin: 40px 0;
  text-align: center;
}

.terms-popup .modal-body .icons div {
  display: inline-block;
  width: 150px;
  text-align: center;
  margin: 10px auto;
}

.terms-popup .modal-body .icons img {
  display: block;
  margin: auto auto 5px;
}

.terms-popup .nn-button-normal {
  margin-bottom: 20px;
}

/* END notePopUp*/

/* start sticky header */

.sticky {
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
  z-index: 97;
}

.sticky.top {
  top: 0;
}

/* end sticky header */

/* start tabs */

.panel {
  margin-top: 45px;
  margin-bottom: 30px;
}

.panel-acount .MuiButtonBase-root {
  margin: 0 auto;
}

.panel-payments-online .MuiTabs-flexContainer {
  justify-content: space-around;
}

@media (max-width: 767.98px) {
  .panel {
    margin-top: 20px;
  }

  .flex-container-nn {
    flex-direction: column;
    gap: 20px !important;
  }
}

.panel .nav-tabs {
  border-bottom: 0;
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media (max-width: 767.98px) and (min-width: 400px) {
  .panel .nav-tabs {
    display: flex;
    justify-content: space-evenly;
  }
}

.panel li.nav-item {
  box-sizing: border-box;
  height: 36px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #cac7c7;
  line-height: 1;
  border-right: 0;
  float: none;
  display: table-cell;
  vertical-align: middle;
  padding: 0 5px;
}

.panel li.nav-item:last-child {
  border-right: 1px solid #cac7c7;
}

@media (max-width: 767.98px) {
  .panel li.nav-item {
    width: 45%;
    margin: 5px 0;
    border-right: 1px solid #cac7c7;
    line-height: 34px;
  }
}

@media (max-width: 399.98px) {
  .panel li.nav-item {
    width: 100%;
    display: block;
  }
}

.panel .panel-heading .nav-link {
  color: #414141;
  border: none;
  border-radius: inherit;
  padding: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel .panel-heading .nav-link.active {
  color: inherit;
}

@media (max-width: 575.98px) {
  .panel .panel-heading .nav-link {
    white-space: nowrap;
  }
}

.panel .panel-heading .nav-item-active {
  border: 1px solid #ea650d !important;
  z-index: 2;
}

.panel .panel-heading .nav-item-active .nav-link {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
}

.panel .panel-body {
  margin-top: 35px;
  margin-bottom: 100px;
}

@media (max-width: 767.98px) {
  .panel .panel-body {
    margin-top: 15px;
  }
}

/* end tabs */

/* start tc/cookies */

#registration-body-info p > b,
#tc-cookies-info p > b {
  margin: 40px 0 20px 0;
  display: block;
}

#registration-body-info h2,
#tc-cookies-info h2 {
  margin-bottom: 30px;
}

#registration-body-info h3,
#tc-cookies-info h3 {
  color: #ee7f00;
  margin: 40px 0 20px 0;
}

#registration-body-info hr,
#tc-cookies-info hr {
  display: none;
}

/* end tc/cookies */

.virtual-link {
  cursor: pointer;
}

.noContentPreview {
  text-align: center;
  font-size: 25px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.noContentPreview .img {
  vertical-align: top;
}

.noContentPreview .text {
  line-height: 45px;
}

select.online-payment-dropdown {
  color: black;
}

select.online-payment-dropdown.grey,
select.online-payment-dropdown option {
  color: gray;
}

.modal {
  width: auto;
  height: auto;
}

.nn-modal-content {
  font-family: Calibri, Arial, sans-serif;
  scrollbar-width: none;
  padding: 30px;
  font-size: 14px;
}

.nn-modal-content .modal-title {
  color: #ee7f00;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  padding-right: 40px;
}

.nn-modal-content::-webkit-scrollbar {
  display: none;
}

.chevron-link-new {
  display: flex;
  text-decoration: none;
  line-height: 24px;
}

.chevron-link-new > a,
.chevron-link-new > .inner {
  display: inline-block;
  position: relative;
  text-decoration: none;
  line-height: 24px;
  cursor: pointer;
}

.chevron-link-new:hover a {
  text-decoration: underline;
  color: #ea650d;
}

.chevron-link-new:hover span {
  color: #ea650d;
}

.chevron-link-new span {
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0;
  color: #414141;
}

.chevron-link-orange span {
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0;
}

.chevron-link-new span a {
  color: #414141;
}

.icon-text {
  display: flex;
}

.flex-container-nn {
  display: flex;
  gap: 60px;
  margin-bottom: 20px;
}

.payment-select-container {
  width: 250px;
}

.cursor-pointer {
  cursor: pointer;
}

.select-nn-placeholder {
  padding-top: 10px;
  font-family: "Calibri", sans-serif;
}

.radiobutton-remove-style {
  font-size: 16px;
  font-weight: 400;
}

/* [data-testid="select"] label {
  font-size: 15px !important;
  margin-top: 7px;
  font-family: Calibri, sans-serif;
} */

.archive-message {
  text-align: right;
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
}

.cs-form-element {
  display: inline-block;
  width: 330px;
}

#heading-nn h5 {
  font-weight: bold !important;
}

#heading-nn h4 {
  font-weight: bold !important;
}

#heading-nn p {
  font-weight: 400 !important;
  margin-top: 5px !important;
}

.nn-design-orangeheader {
  display: flex;
  justify-content: space-between;
}

.nn-design-orangeheader h3,
.nn-design-orangeheader h5 {
  color: #ee7f00 !important;
  font-weight: bold;
}

.nn-design-orangeheader .see-more {
  display: inline-block;
  color: #ee7f00;
  font-size: 16px;
  letter-spacing: 0;
  text-align: right;
  cursor: pointer;
  text-decoration: none;
  line-height: 44px;
  height: 36px;
}

[data-testid="link-carusel"] span {
  color: #ea650d;
}

[data-testid="heroTest"] {
  height: 361px !important;
}

[data-testid="heroTest"] > div:nth-child(2) {
  margin-left: 160px !important;
  max-width: 40%;
  min-width: 30%;
}

@media (max-width: 810px) {
  [data-testid="heroTest"] > div:nth-child(2) {
    margin-left: 50px !important;
    max-width: 60%;
    min-width: 10%;
  }
}

@media (max-width: 560px) {
  [data-testid="heroTest"] > div:nth-child(2) {
    margin-left: 20px !important;
    margin-right: 20px;
    max-width: 100%;
    font-size: smaller;
  }
}

.overflowHidden {
  overflow: hidden !important;
}

.dowload-icon-container {
  display: flex;
}

/* Hack to override nested table styles */
.MuiDataGrid-withBorderColor.MuiDataGrid-withBorderColor.MuiDataGrid-withBorderColor {
  border-color: transparent;
}
