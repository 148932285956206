.autocomplete-container {
  padding: 0;
  border: none;
}

.autcomplete-input {
  /* background-color: red !important; */
  margin-top: 4px;
}

.autocomplete-container .MuiInputBase-root {
  height: 48px;
  font-family: Calibri, Arial, sans-serif;
  font-size: 1rem;
  color: #404040;
  line-height: 1;
  border: none;
  border-radius: 4px;
  align-content: center;
}

.autocomplete-container.MuiOutlinedInput-root {
  padding: 0 5px;
  background-color: #ffffff;
}

.autocomplete-container .Mui-error .MuiOutlinedInput-notchedOutline {
  border-width: 2px;
  border-color: #e64415;
}

.MuiAutocomplete-popper .MuiPaper-root {
  background-color: #ffffff;
  border-color: #e6dfda;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px 4px 12px 0px rgba(65, 65, 65, 0.15);
  box-sizing: border-box;
  margin-top: 4px;
  padding: 8px;
}
.MuiAutocomplete-popper .MuiPaper-root .Mui-focused {
  background-color: #fff7ee;
  border-radius: 4px;
}
.MuiAutocomplete-popper .MuiPaper-root .MuiMenuItem-root {
  font-family: Calibri, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #404040;
  background-color: transparent;
  border-radius: 4px;
  padding: 12px 16px;
  box-sizing: border-box;
  width: 100%;
}
.MuiAutocomplete-popper .MuiPaper-root .Mui-selected {
  background-color: #fff7ee;
  border: 1px solid #ea650d;
}
