#articles-lists .section {
	width: 100%;
	margin-top: 38px;
}

#articles-lists .section .bar {
	display: flex;
	justify-content: space-between;
	align-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: 12px;
	align-items: center;
}

#articles-lists .section .list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 70px;
    row-gap: 10px;
	margin-bottom: 12px;
	border-top: 1px solid #CAC7C7;
	padding-bottom: 20px;
	
}

@media (max-width: 991.98px) {
	#articles-lists .section .list {
		grid-column-gap: 30px;
    	column-gap: 30px;
	}
}

@media (max-width: 575.98px) {
	#articles-lists .section .list {
		grid-template-columns: repeat(1, 1fr);
	}
}

#articles-lists h2  {
	color: #EE7F00;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1;
    margin: 0;
}

#articles-lists .see-more span {
	display: inline-block;
	color: #EE7F00;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	text-align: right;
	cursor: pointer;
}

#articles-lists .see-more img {
	display: inline-block;
	padding: 7px 4px;
	cursor: pointer;
}

#articles-lists .hidden {
	display: none!important;
}

#articles-lists .item {
	padding: 0;
}

#articles-lists .active .item {
	display: block!important;
}

#articles-lists h3 {
	color: #414141;
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0;
	height: 73px;
	line-height: 70px;
	margin: 25px 0 5px;
	overflow: hidden;
}

@media (max-width: 767.98px) and (min-width: 576px) {
	#articles-lists h3 {
		font-size: 20px;
		height: 62px;
		line-height: 60px;
	}
}

#articles-lists h3 b {
	vertical-align: middle;
    display: inline-block;
    line-height: 1;
}

#articles-lists .item img {
	display: block;
	max-width: 100%;
}

#articles-lists .item .img-wrapper {
	margin-bottom: 10px;
}

#articles-lists .img-wrapper img {
	width: 100%;
}

#articles-lists .item span {
	display: block;
	color: #414141;
	font-family: Calibri, Arial, sans-serif;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 20px;
	margin-bottom: 14px;
}

#articles-lists .read-more {
	display: flex;	
	text-decoration: none;
	line-height: 24px;
	cursor: pointer;
}

#articles-lists .read-more img {
	display: block;
	padding: 4px 7px;
	position: absolute;
	top: 0;
	left: 0;
}

#articles-lists .read-more span {
	display: block;	
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;	
	color: #414141;
}