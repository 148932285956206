.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  font-family: Calibri, Arial, sans-serif;
  letter-spacing: 0;
  z-index: 102;
}

.cookie-consent.hidden {
  display: none;
}

.cookie-consent > div {
  padding-top: 15px;
}

.cookie-consent .close {
  position: absolute;
  top: 13px;
  right: 19px;
  cursor: pointer;
  padding: 10px;
}

.cookie-consent .close:before,
.cookie-consent .close:after {
  content: " ";
  width: 2px;
  height: 17px;
  background: #cac7c7;
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
}

.cookie-consent .close:before {
  transform: rotate(45deg);
}

.cookie-consent .close:after {
  transform: rotate(-45deg);
}

.cookie-consent .close:hover:before,
.cookie-consent .close:hover:after {
  background: #414141;
}

.cookie-consent .content {
  display: flex;
}

.cookie-consent .content .left {
  width: calc(100% - 180px);
  margin-right: 50px;
  min-width: 180px;
}

.cookie-consent .content .left b {
  font-size: 18px;
}

.cookie-consent .content .left > p {
  padding: 10px 0;
  max-height: 115px;
  overflow-y: auto;
  color: #414141;
  font-size: 14px;
  line-height: 22px;
}

.cookie-consent .content .right {
  position: relative;
  width: 180px;
}

.cookie-consent .content .right > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  text-align: right;
  width: 100%;
}

.cookie-consent .content .right > div > .nn-button-normal {
  width: 110px;
  font-weight: bold;
}

.cookie-consent .content .right > div > .chevron-link {
  margin: 20px 0px 0px 20px;
  font-weight: bold;
}

/* minimized */

.cookie-consent-minimized {
  position: fixed;
  bottom: 0;
  left: 0;
  font-family: Calibri, Arial, sans-serif;
  letter-spacing: 0;
  border-radius: 0 15px 0 0;
  z-index: 102;
}

.cookie-consent-minimized.hidden {
  display: none;
}

.cookie-consent-minimized .chevron-link {
  margin: 5px 15px 5px 5px;
  font-weight: bold;
}

/* cookie settings modal */

.cookie-settings .modal-dialog {
  max-width: 786px;
  font-family: Calibri, Arial, sans-serif;
}

.cookie-settings .modal-content {
  padding: 35px;
}

.cookie-settings .modal-header {
  display: block;
  border: none;
  padding: 0 0 20px 0;
}

.cookie-settings .modal-header .close {
  font-size: 45px;
  color: #a3a2a2;
  outline: none;
  padding: 0;
  margin: 0;
  font-weight: normal;
  line-height: 24px;
  width: 30px;
  float: none;
  position: absolute;
  top: 2px;
  right: 2px;
}

.cookie-settings .modal-header .close:hover {
  color: #a3a2a2;
}

.cookie-settings .modal-header > div {
  display: flex;
}

.cookie-settings .modal-title {
  color: #ee7f00;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
}

.cookie-settings .modal-description {
  color: #414141;
  font-size: 16px;
  padding-right: 40px;
  margin: 25px 0 0;
  font-weight: bold;
}

@media (max-width: 575.98px) {
  .cookie-settings .modal-header > div {
    display: block;
  }

  .cookie-settings .modal-description {
    width: 100%;
    padding-right: 0;
  }
}

.cookie-settings .accept-cookie-settings {
  margin-top: 30px;
  width: 152px;
  font-weight: bold;
}

.cookie-settings .modal-body {
  padding: 0;
  background-color: #ffffff;
  font-size: 16px;
  color: #414141;
}

.setting-row {
  display: flex;
  border-bottom: 1px solid #cac7c7;
  padding: 20px 10px 20px 0;
}

.setting-row.normal {
  border-bottom: 1px solid #ea650d;
}

.setting-row:last-child {
  border: none;
  padding-bottom: 0;
}

.setting-title {
  width: calc(100% - 100px);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
}

.switch {
  width: 100px;
  height: 32px;
  text-align: right;
}

.switch > div {
  display: inline-block;
  width: 64px;
  height: 32px;
  border-radius: 50px;
}

.switch.off > div {
  background: #f1edeb;
  text-align: left;
}

.switch.on > div {
  background: linear-gradient(90deg, #ea650d 0%, #ea650d 100%);
  text-align: right;
}

.switch > div > span {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  background: #ffffff;
  margin-top: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
}

.switch.off > div > span {
  margin-left: 2px;
}

.switch.on > div > span {
  margin-right: 2px;
}
.cookie-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.header-cookie {
  font-weight: bold;
  font-size: 20px;
}
