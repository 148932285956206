#single-article a {
    color: #ea650d;
}

#single-article .articles-label {
    color: #ee7f00;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 41px;
    margin-bottom: 25px;
    margin-top: 12px;
}

#single-article img {
	max-width: 100%;
}

#single-article .body {
	color: #414141;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0;
}

#single-article p {
	margin: 20px 0;
}

#single-article .highlighted {
	color: #EA650D;
}

#single-article.video b {
	color: #000;
}

#single-article b .highlighted {
	color: #EE7F00;
}

#single-article .big {
	font-size: 21px;
}

#single-article.video .big {
	font-size: 24px;
	line-height: 25px;
}

#single-article .relative {
	position: relative;
}

#single-article .background-separator {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100%;
    top: 0;
    bottom: 0;
    display: block;
    left: calc( ((-100vw + 100%) / 2) );
}