.selected-contract-overview .breadcrumbs {
  margin-top: 20px;
}

.selected-contract-overview .overview {
  margin-top: 23px;
}

.selected-contract-overview .overview .selected-contract-label {
  color: #ee7f00;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 20px;
}

.claimSubmissionButton {
  background-color: lightgray;
  height: fit-content;
  min-width: 64px;
  margin-top: 35px;
  display: inline-flex;
  border-radius: 4px;
}

.claimSubmissionButton button {
  font-size: 1rem;
  height: 32px;
  color: white;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.5;
  padding: 0 16px;
  font-family: Calibri, Arial, Helvetica, sans-serif;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-indent: 0px;
  text-align: center;
  min-width: 64px;
}

.claimSubmissionButton button:hover {
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 499.98px) {
  .selected-contract-overview .overview .selected-contract-label {
    font-size: 30px;
  }
}

@media (max-width: 319.98px) {
  .selected-contract-overview .overview .selected-contract-label {
    font-size: 24px;
  }
}

.selected-contract-overview .overview .insurance-number,
.selected-contract-overview .overview .contracting,
.selected-contract-overview .overview .payment-amount,
.selected-contract-overview .overview .insurance-status {
  display: inline-block;
}

.selected-contract-overview .overview .insurance-number {
  width: 239px;
}

.selected-contract-overview .overview .contracting {
  width: 234px;
}

.selected-contract-overview .overview .payment-amount {
  width: 237px;
}

.selected-contract-overview .overview .insurance-status {
  width: 210px;
}

@media (max-width: 991.98px) {
  .selected-contract-overview .overview > div {
    width: 50% !important;
    margin: 0 0 20px;
  }
}

@media (max-width: 399.98px) {
  .selected-contract-overview .overview > div {
    width: 100% !important;
  }
}

.selected-contract-overview .overview .insurance-number p,
.selected-contract-overview .overview .contracting p,
.selected-contract-overview .overview .payment-amount p,
.selected-contract-overview .overview .insurance-status p {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}

.selected-contract-overview .overview .insurance-number span,
.selected-contract-overview .overview .contracting span,
.selected-contract-overview .overview .payment-amount span,
.selected-contract-overview .overview .insurance-status span {
  color: #414141;
  font-size: 16px;
}

.selected-contract-overview .overview .overview-line {
  box-sizing: border-box;
  border: 1px solid #ee7f00;
}

@media (max-width: 767.98px) {
  .selected-contract-overview .overview .overview-line {
    display: none;
  }
}

.selected-contract-tabs .MuiTabs-flexContainer {
  justify-content: space-between;
}

.selected-contract-tabs.group-policy .MuiTabs-flexContainer {
  justify-content: flex-start;
}

.MuiTab-root {
  font-family: "calibri" !important;
  font-size: 16px !important;
}

.MuiTab-root:not(.Mui-selected) {
  font-weight: normal !important;
}

.textAlignLeft {
  text-align: left;
}

.whiteSpaceNormal {
  white-space: normal !important;
}

.groupPensionIndividual-analysistable
  .MuiDataGrid-row:last-child
  .groupPensionIndividualAnalysisRowCell {
  border-bottom: none !important;
}

.MuiDataGrid-row--grouprow {
  background-color:rgb(230,101,13,0.05);
}