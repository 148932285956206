#account-tabs .account-details {
  display: flex;
  justify-content: flex-start;
}

#account-tabs .account-details > div {
  vertical-align: top;
  display: inline-block;
  width: 25%;
  word-break: break-all;
  padding-right: 20px;
}

#account-tabs .account-details .personal label,
#account-tabs .account-details .address-contact label,
#account-tabs .account-details .id label,
#account-tabs .account-details .profession-ensurance label {
  color: #ea650d;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 13px;
}

#account-tabs .account-details .personal div,
#account-tabs .account-details .address-contact div,
#account-tabs .account-details .id div,
#account-tabs .account-details .profession-ensurance div {
  margin-bottom: 20px;
}

#account-tabs .account-details .personal p,
#account-tabs .account-details .address-contact p,
#account-tabs .account-details .id p,
#account-tabs .account-details .profession-ensurance p {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

#account-tabs .account-details .personal span,
#account-tabs .account-details .address-contact span,
#account-tabs .account-details .id span,
#account-tabs .account-details .profession-ensurance span {
  color: #414141;
  font-size: 16px;
}

#account-tabs .account-details .address-contact .address,
#account-tabs .account-details .address-contact .contact {
  display: block;
}

#account-tabs .account-details .address-contact .address {
  margin-bottom: 40px;
}

#account-tabs .account-details .profession-ensurance .profession,
#account-tabs .account-details .profession-ensurance .ensurance {
  display: block;
}

#account-tabs .account-details .profession-ensurance .profession {
  margin-bottom: 40px;
}

#account-tabs .account-details .profession-ensurance .ensurance label {
  display: block;
  margin-bottom: 2px;
}

@media (max-width: 767.98px) {
  #account-tabs .account-details {
    display: block;
  }

  #account-tabs .account-details > div {
    width: 50%;
  }
}

@media (max-width: 399.98px) {
  #account-tabs .account-details > div {
    width: 100%;
    padding-right: 0;
  }
}
