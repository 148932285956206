/* start body-unregistered-download.css */

.unregistered-download a {
  display: block;
  color: #000;
}
.unregistered-download a.active {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.unregistered-download b {
  padding-left: 10px;
}

.unregistered-download img {
  max-width: 20px;
}

/* end body-unregistered-download.css */
