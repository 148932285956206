.modalContentContainer {
    padding-left: 42px;
    padding-right: 42px;
    max-height: 720px;

    .header {
        label {
            color: #ee7f00;
            margin-bottom: 38px;
        }
    }

    .modalRow {
        display: flex;
        flex: 1 1 0;
        align-items: center;
        width: 100%;
        margin-bottom: 34px;

        label:first-of-type {
            margin-right: 27px;
            color: #4a4a4a;
            overflow-wrap: break-word;
            max-width: 320px;
            width: 100%;
        }
    }
}

.detailPanelContainer {
    padding-left: 89px;
    padding-right: 46px;
    background-color: #f8f6f5;
}

.insuredName {
    white-space: normal !important;
}

.radioBtnsGroupContainer {
    display: flex;
    margin-top: 30px;
}

.detailPanelContainerRejection {
    padding-left: 100px;
    padding-top: 30px;
    padding-bottom: 25px;
    padding-right: 46px;
    background-color: #f8f6f5;
}

.claimRejectionReasonSection {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding-left: 10px;

    label {
        flex: 0 0 143px;
        margin-right: 30px;
        text-align: right;
    }

    p {
        margin: 0;
        max-width: 535px;
    }
}

.claimRejectionNotesSection {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;

    label {
        flex: 0 0 143px;
        margin-right: 30px;
        text-align: right;
    }

    p {
        margin: 0;
        max-width: 535px;
    }
}

.claimPendingRefundNotesSection {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;

    label {
        margin-right: 30px;
        text-align: right;
    }

    p {
        margin-right: 5%;
    }
}

.rejectionDetailsPanelSeparator {
    border: 1px solid #f1edeb;
}

.mobileViewCollapsibleElementContainer > div {
    border-color: #e5ded9 !important;
}

.mobileViewCollapsibleElementContainer.mobileViewExpandedBackground {
    background-color: #f8f6f5 !important;
}

.mobileViewElementContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.chevroDown {
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
}

.claimsDocumentLabelIconGroup {
    display: flex;
    flex-direction: row;
    margin-left: -5px;
}

.portalSubmittedDash {
    font-weight: normal;
}

.basicProperties {
    label {
        margin-bottom: 10px;
    }
}

.mobileViewBenefitName {
    white-space: normal !important;
}

.mobileViewRejectionContainer {
    padding-right: 30px;
    padding-left: 10px;
    max-width: 320px;
    height: 100%;

    p {
        margin: 0;
        margin-top: 5px;
    }
}

.mobileViewModalContentContainer {
    padding-left: 42px;
    padding-right: 42px;
    max-height: 720px;

    .header {
        label {
            color: #ee7f00;
            margin-bottom: 38px;
            font-size: small;
        }
    }

    .modalRow {
        display: flex;
        flex: 1 1 0;
        align-items: center;
        width: 100%;
        margin-bottom: 34px;

        label:first-of-type {
            margin-right: 27px;
            color: #4a4a4a;
            overflow-wrap: break-word;
            max-width: 320px;
            width: 100%;
            font-size: x-small;
        }
    }
}

.mobileView.radioBtnsGroupContainer {
    max-width: 200px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mobileViewNoRecords {
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectedFilesContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    div {
        padding: 5px 10px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        color: #414141;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        white-space: normal;
        overflow-wrap: anywhere;
        margin-top: 5px;
        margin-bottom: 5px;

        svg {
            margin-left: 10px;
        }
    }
}

.responsiveFontSize {
    font-size: 14px !important;

    label {
        font-size: 14px !important;
    }
}

.uploadFilesSection {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;

    .selectedFilesSection {
        margin-left: 20px;
        margin-right: 20px;
        flex: 1;
    }
}

.filesSubmitButton {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
}

.mobileUploadFilesSection {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    width: 215px;

    .selectedFilesSection {
        margin-top: 20px;
    }
}

.disabledBtn {
    button {
        opacity: 0.3;
    }
}
