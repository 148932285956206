#account-tabs .contact-info-container {
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-right: 110px;
}

#account-tabs .contact-time-container {
  display: inline-block;
  position: relative;
}

#account-tabs .contact-info-container label,
#account-tabs .contact-time-container label {
  color: #EA650D;
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

#account-tabs .contact-info-container span,
#account-tabs .contact-time-container span {
  color: #414141;
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
}