.survey-form-wrapper {
  padding: 40px;
}

.survey-submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.section-title {
  font-family: Calibri, Arial, sans-serif;
  color: var(--accent-color);
}

.radiogroup-grid {
  display: grid;
  grid-template-columns: 80% 1fr 1fr;
  gap: 1rem;
}

.radiogroup-grid-labels-row {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / 4;
  border-bottom: 1px solid var(--gray-3);
  padding: 16px 0;
}

.radiogroup-grid-labels {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 2 / 4;
  text-transform: uppercase;
  justify-items: center;
  font-family: Calibri, Arial, sans-serif;
  font-weight: 700;
}

.radiogroup-grid-question {
  font-family: Calibri, Arial, sans-serif;
  border-bottom: 2px solid var(--gray-2);
  padding-bottom: 16px;
}

.radiogroup-grid-fields {
  all: unset;
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / 4;
  border-bottom: 1px solid var(--gray-3);
  padding-bottom: 16px;
}

.radiogroup-grid-legend {
  line-height: 1.5;
  word-break: break-word;
  font-family: Calibri, Arial, sans-serif;
}

.radiogroup-grid-fields fieldset {
  all: unset;
  display: contents;
}

.radiogroup-grid-fields input[type="radio"] {
  accent-color: var(--accent-color);
  width: 32px;
  height: 32px;
  justify-self: center;
}

.radiogroup-grid-textarea {
  margin-top: 16px;
  grid-column: 1 / 4;
}

.score-group {
  display: flex;
  justify-content: space-between;
}

.score-button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid var(--gray-1);
  border-radius: 4px;
  cursor: pointer;
}

.score-label {
  pointer-events: none;
  font-family: Calibri, Arial, sans-serif;
}

.survey-score-reason {
  margin-top: 24px;
}

.survey-score-reason-length {
  display: block;
  text-align: right;
  padding: 10px;
  font-size: 14px;
}

.score-button:focus {
  border-color: var(--accent-color);
  box-shadow:
    0 2px 16px 0 rgba(114, 114, 114, 0.08),
    0 1px 6px 0 rgba(128, 128, 128, 0.1),
    0 1px 2px 0 rgba(102, 102, 102, 0.1);
}

.score-button[data-state="checked"] {
  border-color: var(--accent-color);
  background-color: var(--accent-color);
  color: var(--white);
}

.score-input {
  transform: translateX(-100%);
  position: absolute;
  pointer-events: none;
  opacity: 0;
  margin: 0px;
  width: 25px;
  height: 25px;
}

.survey-question-text {
  font-family: Calibri, Arial, sans-serif;
  font-weight: 700;
}

.score-minmax-labels {
  font-family: Calibri, Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.survey-question-block:has(+ .survey-question-block)::after {
  content: "";
  width: 288px;
  height: 1px;
  background-color: var(--gray-3);
  display: block;
  margin: 40px 0;
}

.survey-create-account-link {
  background-color: var(--accent-color);
  background-image: linear-gradient(
    45deg,
    var(--accent-color),
    var(--orange-2)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-weight: 700;
}

.survey-modal-wrapper {
  padding: 32px;
}

.survey-modal-close-button {
  display: flex;
  justify-content: flex-end;
}
