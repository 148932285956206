#inbox-message .message-header {
	margin-top: 15px;
}

#inbox-message .message-body {
	margin-top: 23px;
}

#inbox-message .message-body p {
	color: #A0A0A0;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0;
}

#inbox-message .message-body b {
	font-size: 1.27rem;
}

#inbox-message .header-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 38px;
}

#inbox-message .header-column {
	position: relative;
}

#inbox-message .header-column:last-child {
	text-align: right;
}

#inbox-message .category {
    background-color: #E5DED9;
    display: inline-block;
    padding: 2px 8px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border-radius: 4px;
    letter-spacing: 0;
}

#inbox-message .button a {
	display: inline-block;
    border: 1px solid #EA650D;
    border-radius: 3px;
    background-color: #FFFFFF;
    text-decoration: none;
    height: 38px;
    line-height: 34px;
    box-sizing: border-box;
    text-align: center;
    padding: 0 17px;
}

#inbox-message .button .restore {
	padding: 0 15px 0 18px;
}

#inbox-message .button a span {
	color: #EA650D;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
}

#inbox-message .button a img {
	margin-left: 9px;
	margin-right: 2px;
}

#inbox-message .button .restore img {
	margin-left: 13px;
	margin-right: 0;
}

#inbox-message .button > span {
	font-weight: bold;
    font-size: 16px;
    margin-right: 20px;
}

#inbox-message .hidden {
	display: none;
}

#inbox-message .goback {
	line-height: 1;
    position: absolute;
    top: 12px;
    left: 4px;
}

#inbox-message .title {
	color: #ee7f00;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0;
    display: block;
    margin: 0 38px;
}

#inbox-message time {
	color: #545454;
	font-size: 16px;
	letter-spacing: 0;
	display: block;
	margin-top: 5px;
}

#inbox-message .header-column .button {
	text-align: right;
}

#inbox-message .button.archived > span {
	margin-right: 0;
}

@media (max-width: 767.98px) {
	#inbox-message .header-row {
		display: block;
	}

	#inbox-message .header-column:last-child {
		text-align: left;
	}

	#inbox-message .title {
		margin: 0 0 0 38px;
	}

	#inbox-message .header-column .button {
		margin-top: 20px;
		text-align: left;
	}
}

@media (max-width: 499.98px) {
	#inbox-message .title {
		font-size: 30px;
	}
}

@media (max-width: 399.98px) {
	#inbox-message .button a {
		display: block;
	}
}

@media (max-width: 319.98px) {
	#inbox-message .title {
		font-size: 24px;
	}
}