#message-count-navbar {
  position: absolute;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  background-color: #e64415;
  border-radius: 20px;
  right: -25px;
}
