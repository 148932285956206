.selected-contract-tabs .selected-contract-intermediary {
  margin-left: 50px;
}

@media (max-width: 600px) {
  .selected-contract-tabs .selected-contract-intermediary {
    margin-left: 0;
  }
}

.selected-contract-tabs .selected-contract-intermediary > div {
  display: inline-block;
  vertical-align: top;
  width: 30%;
  margin-right: 15%;
  word-break: break-word;
}

@media (max-width: 767.98px) {
  .selected-contract-tabs .selected-contract-intermediary > div {
    width: 45%;
    margin-right: 5%;
  }
}

@media (max-width: 499.98px) {
  .selected-contract-tabs .selected-contract-intermediary > div {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.selected-contract-tabs .selected-contract-intermediary .intermediary p,
.selected-contract-tabs
  .selected-contract-intermediary
  .sales-office-details
  p {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.selected-contract-tabs .selected-contract-intermediary .intermediary span,
.selected-contract-tabs
  .selected-contract-intermediary
  .sales-office-details
  span {
  color: #414141;
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
}

.selected-contract-tabs .selected-contract-intermediary .intermediary img,
.selected-contract-tabs
  .selected-contract-intermediary
  .sales-office-details
  img {
  margin-right: 8px;
  margin-bottom: 2px;
}

.selected-contract-tabs
  .selected-contract-intermediary
  .intermediary
  .intermediary-email {
  margin-right: 5px;
}
