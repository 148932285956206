.registration #person-entity,
.registration #company-entity {
  display: none;
}

.registration #person-entity + label:before,
.registration #company-entity + label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 7px;
  margin: 0 7px 0 0;
  height: 25px;
  width: 25px;
  border: 1px solid #e5ded9;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.registration #person-entity:checked + label:after,
.registration #company-entity:checked + label:after {
  content: " ";
  display: block;
  position: absolute;
  top: 13px;
  left: 6px;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  background-color: #ea650d;
}

.registration .person-entity-label,
.registration .company-entity-label {
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  top: -7px;
  display: inline-block !important;
}

.registration .person-entity-label {
  margin-right: 35px;
}

@media (max-width: 319.98px) {
	.registration .person-entity-label {
    margin-right: 50%;
  }
}