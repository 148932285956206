.selected-contract-tabs .selected-contract-payments {
  margin-bottom: 30px;
}
.selected-contract-tabs .selected-contract-payments .MuiDataGrid-columnHeaderTitle:nth-child(1) {
  text-align: start;
}

.selected-contract-tabs .selected-contract-payments p {
  color: #545454;
  font-size: 16px;
  display: inline-block;
}

.selected-contract-tabs .selected-contract-payments p:first-child {
  font-weight: bold;
}

.selected-contract-payments-title {
  margin: 0 20px;
}

.selected-contract-tabs .selected-contract-payments .payment-frequency {
  color: #4a4a4a;
  font-size: 16px;
  margin-left: 5px;
}

.selected-contract-tabs .selected-contract-payments .selected-contract-payments-table {
  margin-bottom: 20px;
  margin-top: 4px;
}

.selected-contract-tabs .selected-contract-payments .selected-contract-payments-table img {
  margin-right: 5px;
}

.selected-contract-tabs .selected-contract-payments .selected-contract-payments-table img.download {
  margin-left: 10px;
}
.selected-contract-tabs .selected-contract-payments .selected-contract-payments-table a.active {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.selected-contract-tabs .selected-contract-payments .selected-contract-payments-table .overdue {
  color: #ea650d;
  font-size: 16px;
  letter-spacing: 0;
}

.selected-contract-tabs .selected-contract-payments .selected-contract-payments-table .pending {
  color: #e64415;
  font-size: 16px;
}

.selected-contract-tabs .selected-contract-payments .selected-contract-payments-table .collapsing {
  transition: none !important;
}

.selected-contract-tabs .selected-contract-payments .selected-contract-payments-table b.hidden {
  display: none;
  font-size: 16px;
}
.payments-table .clarification {
  color: #bcbcbc;
  font-size: 16px;
  letter-spacing: 0;
}

@media (max-width: 767.98px) {
  .selected-contract-tabs .selected-contract-payments .selected-contract-payments-table b.hidden {
    display: block;
  }
}

.selected-contract-tabs .installment-download-icon {
  display: inline-block;
  margin-left: 5px;
}

@media (max-width: 992px) and (min-width: 768px) {
  .selected-contract-tabs .installment-download-icon {
    margin-left: 0px;
  }
}

.selected-contract-tabs .installment-download-icon:hover {
  cursor: pointer;
}

.installment-download-popover-text {
  margin: auto;
  font-size: 12px;
}
