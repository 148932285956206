.articles-carousel {
	font-family: Calibri, Arial, sans-serif;
}

.articles-carousel .section {
	width: 100%;
	margin-top: 38px;
}

.articles-carousel .bar {
	display: flex;
	justify-content: space-between;
	align-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: 10px;
	align-items: center;
}

.articles-carousel h2  {
	color: #EA650D;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1;
    margin: 0;
}

@media (max-width: 575.98px) {
	.articles-carousel h2  {
		font-size: 21px;
	}
}

.articles-carousel h2 .brand {
	color: #EE7F00;
}

.articles-carousel .see-more {
	display: inline-block;
    color: #EE7F00;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: right;
    cursor: pointer;
    text-decoration: none;
    line-height: 44px;
    height: 36px;
}

.articles-carousel .list {
	border-top: 1px solid #CAC7C7;
	margin-bottom: 12px;
	padding-bottom: 20px;
}

.articles-carousel .carousel {
	display: flex;
	justify-content: space-between;
	align-content: flex-start;
	flex-wrap: wrap;
	align-items: flex-start;
}

.articles-carousel .position.left {
	width: 5%;
    min-width: 40px;
    text-align: left;
}

.articles-carousel .position.center {
	width: calc(90% - 40px);
}

.articles-carousel .position.right {
    width: 5%;
    min-width: 40px;
    text-align: right;
}

@media (max-width: 575.98px) {
	.articles-carousel .position.center {
		width: calc(87% - 40px);
	}
}

@media (max-width: 399.98px) {
	.articles-carousel .position.center {
		width: calc(85% - 40px);
	}
}

@media (max-width: 319.98px) {
	.articles-carousel .position.center {
		width: calc(85% - 20px);
	}

	.articles-carousel .position.left,
	.articles-carousel .position.right {
		min-width: 20px;
	}

	.articles-carousel .item {
		padding: 0 0 10px 0;
	}

	.articles-carousel .controls a img {
		max-height: 20px;
	}
}

.articles-carousel .controls {
	margin-top: 110px;
}

.articles-carousel.single .controls {
	margin-top: 130px;
}

@media (max-width: 767.98px) {
	.articles-carousel .controls {
		margin-top: 130px;
	}
}

.articles-carousel .controls a {
	display: block;
}

.articles-carousel .carousel-item {
	align-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: 12px;
}

.articles-carousel .carousel-item.active,
.articles-carousel .carousel-item-left,
.articles-carousel .carousel-item-right {
	display: flex !important;
}

.articles-carousel .item {
	width: 33%;
    padding: 0 15px 10px 15px;
}

.articles-carousel.single .item {
	width: 100%;
}

@media (max-width: 767.98px) {
	.articles-carousel .item {
		width: 100%;
		height: auto;
	}
}

.articles-carousel h3 {
	color: #414141;
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0;
	height: 75px;
	line-height: 72px;
	margin: 25px 0 10px;
	overflow: hidden;
}

.articles-carousel h3 b {
	vertical-align: middle;
    display: inline-block;
	line-height: 1;
}

@media (max-width: 991.98px) {
	.articles-carousel h3 {
		font-size: 20px;
		height: 62px;
		line-height: 60px;
	}
}

@media (max-width: 767.98px) {
	.articles-carousel h3 {
		font-size: 20px;
		height: 42px;
		line-height: 40px;
	}
}

.articles-carousel .item img {
	display: block;
	margin-bottom: 10px;
	max-width: 100%;
	max-height: 126px;
}

.articles-carousel.single .item img {
	max-height: 250px;
	margin: 0 auto 20px;
}

@media (max-width: 767.98px) {
	.articles-carousel .item img {
		max-height: 250px;
		margin: 0 auto 20px;
	}
}

.articles-carousel .item span:not(.read-more) {
	display: block;
	color: #414141;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 20px;
	margin-bottom: 14px;
}

@media (max-width: 991.98px) {
	.articles-carousel .item span:not(.read-more) {
		font-size: 14px;
	}
}

.articles-carousel .read-more {
	display: flex;	
	text-decoration: none;
	line-height: 24px;
}

.articles-carousel .read-more img {
	display: block;
	padding: 4px 7px;
	position: absolute;
	top: 0;
	left: 0;
}

.articles-carousel .read-more span {
	display: block;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	color: #414141;
}