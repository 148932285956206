.contracts.table-container {
  margin-top: 105px;
}

.contracts.table-container .MuiDataGrid-virtualScrollerContent {
  cursor: pointer;
}

.contracts .table-label {
  color: #ea650d;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 30px;
}

@media (max-width: 767.98px) {
  .contracts.table-container {
    margin-top: 30px;
  }
}

.contracts .overdue {
  color: #ea650d !important;
}

.contracts .overdue span {
  color: #414141 !important;
}

.contracts b.hidden {
  display: none;
}

@media (max-width: 767.98px) {
  .contracts b.hidden {
    display: block;
  }
}
