.claimSubmissionWizardContainer {
  h4 {
    color: #ea650d;
  }

  .stepIndicatorAlignWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .stepIndicatorContainer {
      max-width: 805px;
      width: 100%;
    }
  }

  .formRowContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 20px;
    justify-content: space-between;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    & > * {
      box-sizing: border-box;
      flex: 0 0 50%;
      
      @media (min-width: 768px) {
        max-width: 360px;
      }
      
      @media (min-width: 992px) {
        max-width: 480px;
      }
    }

    .inputLabel {
      display: inline-block;
      height: 48px;
    }
  }

  .termsConditions {
    display: flex;
    span {
      font-weight: normal !important;
    }

    button {
      color: #ea650d;
      font-weight: bold;
      border: none;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }
  }

  .uploadDocsModalBtnContainer {
    margin-top: 30px;
    margin-bottom: 30px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4a4a4a;
      font-weight: bold;
      font-size: 16px;
      border: 1px dashed #4a4a4a;
      border-radius: 5px;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
      height: 35px;
      width: 210px;
    }
  }

  .step3BtnsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    .newClaimBtnContainer {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4a4a4a;
        font-weight: bold;
        font-size: 16px;
        border: 1px dashed #4a4a4a !important;
        border-radius: 5px;
        background-color: transparent;
        padding: 0 10px;
        cursor: pointer;
        height: 35px;
      }
    }

    .submitBtnContainer {
      min-width: 130px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .buttonsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .nextStepBtn {
    button {
      height: 35px !important;
      min-height: 35px !important;
      background-color: #ea650d !important;
    }
  }

  .previousStepBtn,
  .claimsListBtn {
    button {
      height: 35px !important;
      min-height: 35px !important;
      background-color: white !important;
      border: 2px solid #4a4a4a;
      color: #4a4a4a;
    }
  }

  .claimsListBtn,
  .nextStepBtn {
    display: inline-block;
  }

  .claimsListBtn {
    margin-right: 10px;
  }

  .disabledBtn {
    button {
      opacity: 0.3;
    }
  }

  .compulsoryNote {
    margin-top: 30px;

    label {
      font-style: italic;
      font-size: 15px;
      font-weight: normal;
    }
  }

  .expensesImgsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    .expenseImgElement {
      margin: 0 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 130px;
      border-bottom: 4px solid transparent;

      label {
        font-weight: normal !important;
        font-size: 1rem;
        cursor: pointer !important;
      }
    }

    .expenseImgElementSelected {
      border-bottom: 4px solid #ea650d;
    }

    .expenseImgElementDisabled {
      opacity: 0.3;
    }
  }

  .expensesImgsContainerJustOne {
    justify-content: flex-start;
  }

  .checkBoxContainer {
    label {
      font-weight: normal;
      font-size: 1rem;
    }
  }

  .selectedFilesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    div {
      padding: 5px 10px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      white-space: normal;
      overflow-wrap: anywhere;
      margin-top: 5px;
      margin-bottom: 5px;

      svg {
        margin-left: 10px;
      }
    }
  }

  .filesTableContainer {
    width: 100%;
    margin-bottom: 60px;
    min-width: 320px;

    .filesTableColumnsHeader {
      border-bottom: 2px solid #ea650d;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 20px;

      label {
        flex: 1 1 50%;
      }
    }

    .filesTableRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 2px solid #f1edeb;

      .rowElement {
        flex: 1 1 50%;
        min-height: 67px;

        label {
          font-size: 1rem;
          font-weight: normal;
          white-space: normal;
        }

        span,
        label {
          color: #4a4a4a;
        }
      }

      .firstElement {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .noFileSelected {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #414141;
      background-color: rgba(255, 255, 255, 0.38);
      height: 140px
    }
  }

  .expenseTypeImgInList {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .actionsCell {
    display: flex;
    flex-direction: row;

    span {
      cursor: pointer;
    }
  }

  .claimCountingBadge {
    height: 17px;
    width: 17px;
    background-color: #e64415;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
}

.note {
  margin-top: 30px;

  label {
    font-style: italic;
    font-weight: normal;
    font-size: 1rem;
  }
}

.expenseCategoryPopoverSection {
  * {
    display: inline !important;
    font-size: 15px !important;
  }
}

.modalFileContainer {
  padding-left: 30px;
  padding-right: 30px;

  .note {
    margin-top: 0;
  }

  .header {
    margin-bottom: 10px;
    color: #ee7f00 !important;

    h4 {
      color: #ee7f00 !important;
    }
  }

  .modalRow {
    margin-bottom: 30px;
  }

  .fileToBeUploaded {
    label {
      color: #4a4a4a;
      overflow-wrap: break-word;
    }
  }

  .disabledBtn {
    button {
      opacity: 0.3;
    }
  }

  .addFileBtnContainer {
    display: flex;
    flex-direction: row-reverse;

    button {
      height: 35px !important;
      min-height: 35px !important;
      background-color: #ea650d !important;
    }
  }
}

.claimsTotalAmount,
.claimsDocumentsCount {
  display: flex;
}

.claimsTotalAmount h5:nth-child(2),
.claimsDocumentsCount h5:nth-child(2) {
  color: #ea650d;
}

.claimsTotal {
  display: flex;
  justify-content: space-between;
}

.submissionModalLoading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submissionModalResult {
  padding: 5px 30px;

  .header {
    color: #ee7f00 !important;

    h4 {
      color: #ee7f00 !important;
    }
  }

  .submissionModalResultDiv {
    display: flex;
    flex-direction: column;
    p {
      margin-top: 5px;
      margin-bottom: 20px;
      white-space: pre-line;
    }

    a {
      align-self: flex-end;
    }
  }
}

@media (max-width: 600px) {
  .claimSubmissionWizardContainer {
    .formRowContainer {
      .inputElement {
        min-width: 300px;
      }

      .firstElement,
      .secondElement {
        padding: 0;
      }
    }
  }
}

@media (max-width: 393px) {
  .responsiveHeaderFontSize {
    font-size: 1rem !important;

    label {
      font-size: 1rem !important;
    }
  }

  .filesTableScrollContainer {
    overflow-x: scroll;
  }

  .formRowContainer {
    .inputElement {
      min-width: 200px !important;
    }
  }

  .responsiveFontSize,
  .expenseImgElement {
    font-size: 14px !important;

    label {
      font-size: 14px !important;
    }
  }

  .claimsTotal {
    flex-direction: column;
  }
}

@media (max-width: 417px) {
  .step3BtnsRow {
    justify-content: center !important;

    .submitBtnContainer {
      min-width: 130px;
      min-height: 60px;
      justify-content: center !important;
    }
  }
}
