.claims [data-testid="Table"] span {
  white-space: normal;
}
.claims [data-testid="Table"] .MuiDataGrid-columnHeaderTitle {
  text-align: left !important;
}

.claims .MuiDataGrid-virtualScrollerContent {
  cursor: pointer;
}

.claims.table-container {
  margin-top: 50px;
}

.textAlignLeft .MuiDataGrid-columnHeaderTitle{
  text-align: left !important;
}

.flexAlignCenter {
  display: flex !important;
  flex: 1 1 0 !important;
  align-items: center !important;
  justify-content: center !important;
}

.mobileViewRejectionContainer > .MuiButtonBase-root:hover {
  background-color: inherit !important;
}

.MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active {
  font-weight: bold !important;
}

.MuiStepLabel-labelContainer .MuiStepLabel-label {
  font-family: Calibri, Arial, sans-serif !important;
  text-decoration: none !important;
}

#menu-DoctorsSpeciality button, #menu-ReceiptAmount button, 
#menu-FileUploadType button, #menu-Contract button,
#menu-MainInsuredMember button, #menu-ClaimReason button,
#menu-ExpenseCategory button{
      white-space: normal !important;
      overflow-wrap: anywhere !important;
      text-align: left !important;
}


@media (max-width: 991px) {
  #mui-component-select-Contract,
  #mui-component-select-MainInsuredMember {
    white-space: normal !important;
  }
}

@media (max-width: 600px) {
  #mui-component-select-Contract,
  #mui-component-select-MainInsuredMember {
    white-space: nowrap !important;
  }
}