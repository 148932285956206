.selected-contract-tabs .selected-contract-details .insurance-details,
.selected-contract-tabs .selected-contract-details .payments-details,
.selected-contract-tabs .selected-contract-details .insured-beneficiaries {
  display: inline-block;
  position: relative;
}

.insured-beneficiaries .insured label {
  margin-top: 15px;
}

.claims-modal-content {
  margin-top: -30px;
}

.selected-contract-tabs .selected-contract-details > div {
  vertical-align: top;
  margin-left: 3%;
  width: 30%;
  word-break: break-word;
  margin-top: 20px;
}

@media (max-width: 599.72px) {
  .selected-contract-tabs .selected-contract-details > div {
    width: 100%;
    margin-left: 0;
  }
}

.selected-contract-tabs .selected-contract-details .insurance-details label,
.selected-contract-tabs .selected-contract-details .payments-details label,
.selected-contract-tabs .selected-contract-details .insured-beneficiaries label {
  color: #ee7f00;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
}

.selected-contract-tabs .selected-contract-details .insurance-details div,
.selected-contract-tabs .selected-contract-details .payments-details div {
  margin-bottom: 17px;
}

.selected-contract-tabs .selected-contract-details .insurance-details p,
.selected-contract-tabs .selected-contract-details .payments-details p {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.selected-contract-tabs .selected-contract-details .insurance-details span,
.selected-contract-tabs .selected-contract-details .payments-details span,
.selected-contract-tabs .selected-contract-details .insured-beneficiaries span {
  color: #414141;
  font-size: 16px;
}

.selected-contract-tabs .selected-contract-details .insured-beneficiaries span {
  margin-right: 5px;
  padding-left: 0;
}

.selected-contract-tabs .selected-contract-details .insured-beneficiaries a {
  display: flex;
  align-items: center;
}

.selected-contract-tabs .selected-contract-details .insured-beneficiaries .insured,
.selected-contract-tabs .selected-contract-details .insured-beneficiaries .beneficiaries {
  display: block;
  margin-bottom: 30px;
}

/* .selected-contract-tabs .selected-contract-details .insured-beneficiaries .insured label,
.selected-contract-tabs .selected-contract-details .insured-beneficiaries .beneficiaries label {
  display: block;
} */

.selected-contract-tabs .selected-contract-details .insured-beneficiaries .chevron-link {
  margin: 0 0;
  width: 100%;
}

/* .selected-contract-tabs .selected-contract-details .insured-beneficiaries .insured img,
.selected-contract-tabs .selected-contract-details .insured-beneficiaries .beneficiaries img {
  margin-right: 7px;
} */

.selected-contract-tabs .selected-contract-details .insured-beneficiaries .collapsing {
  transition: none !important;
}

.insured-content {
  padding-left: 18px;
}
.insured-content {
  padding-left: 18px;
}

.insured-content .modal-dialog {
  max-width: 620px;
}

.insured-content h4 {
  color: #ee7f00;
}

.insured-content .modal-description {
  display: block;
  color: #414141;
  font-size: 16px;
  display: inline;
}
.insured-content #percentage {
  padding-left: 20px;
}

.insured-content .modal-body {
  display: flex;
}

@media (max-width: 319.98px) {
  .insured-content .modal-header,
  .insured-content .modal-body {
    padding-left: 20px;
  }

  .insured-content .modal-title {
    font-size: 20px;
    line-height: 20px;
  }
}

.insured-content .modal-body > div {
  display: inline-block;
  width: 65%;
  margin-right: 4%;
  vertical-align: top;
}

@media (max-width: 499.98px) {
  .insured-content .modal-body > div {
    width: 100%;
    margin-right: 0;
  }
}

/* .selected-contract-tabs .selected-contract-details .insured-beneficiaries .insured-information-1 div,
.selected-contract-tabs .selected-contract-details .insured-beneficiaries .insured-information-2 div,
.selected-contract-tabs .selected-contract-details .insured-beneficiaries .beneficiaries-information-1 div,
.selected-contract-tabs .selected-contract-details .insured-beneficiaries .beneficiaries-information-2 div {
  margin-bottom: 10px;
} */

.insured-content .beneficiaries-information {
  display: block !important;
}

.insured-content .insured-information-1 div,
.insured-content .insured-information-2 div,
.insured-content .beneficiaries-information-1 div,
.insured-content .beneficiaries-information-2 div,
.insured-content .beneficiaries-information div {
  margin-bottom: 10px;
}

/* .insured-content .insured-information-1 p,
.insured-content .insured-information-2 p,
.insured-content .beneficiaries-information-1 p,
.insured-content .beneficiaries-information-2 p,
.insured-content .beneficiaries-information p {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
} */

.insured-content .insured-information-1 span,
.insured-content.insured-information-2 span,
.insured-content .beneficiaries-information-1 span,
.insured-content .beneficiaries-information-2 span,
.insured-content .beneficiaries-information span {
  color: #414141;
  font-size: 16px;
  margin-left: 0px;
  margin-top: -3px;
}

/* .insured-content .insurance-details-column{
  margin-top: 20px;
} */
